<template>
  <mdb-container>
    <mdb-row class="mt-5 align-items-center justify-content-start">
      <h4 class="demo-title"><strong>Features Sections</strong></h4>
      <a href="https://mdbootstrap.com/docs/vue/sections/features/" waves-fixed class="border grey-text px-2 border-light rounded ml-2" target="_blank"><mdb-icon icon="graduation-cap" class="mr-2"/>Docs</a>
    </mdb-row>
    <hr />
    <mdb-container>
      <section class="text-center my-5">
        <h2 class="h1-responsive font-weight-bold my-5">Why is it so great?</h2>
        <p class="lead grey-text w-responsive mx-auto mb-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
        <mdb-row>
          <mdb-col md="4">
            <mdb-icon icon="chart-area" size="3x" class="red-text"/>
            <h5 class="font-weight-bold my-4">Analytics</h5>
            <p class="grey-text mb-md-0 mb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit maiores aperiam minima assumenda deleniti hic.</p>
          </mdb-col>
          <mdb-col md="4">
            <mdb-icon icon="book" size="3x" class="cyan-text"/>
            <h5 class="font-weight-bold my-4">Tutorials</h5>
            <p class="grey-text mb-md-0 mb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit maiores aperiam minima assumenda deleniti hic.</p>
          </mdb-col>
          <mdb-col md="4">
            <mdb-icon fas icon="comments" size="3x" class="orange-text"/>
            <h5 class="font-weight-bold my-4">Support</h5>
            <p class="grey-text mb-md-0 mb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit maiores aperiam minima assumenda deleniti hic.</p>
          </mdb-col>
        </mdb-row>
      </section>

      <section class="my-5">
        <h2 class="h1-responsive font-weight-bold text-center my-5">Why is it so great?</h2>
        <p class="lead grey-text w-responsive text-center mx-auto mb-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
        <mdb-row>
          <mdb-col md="4" class="md-0 mb-5">
            <mdb-row>
              <mdb-col lg="2" md="3" size="2">
                <mdb-icon icon="bullhorn" size="2x" class="blue-text"/>
              </mdb-col>
              <mdb-col lg="10" md="9" size="10">
                <h4 class="font-weight-bold">Marketing</h4>
                <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                <mdb-btn color="primary" size="sm">Learn more</mdb-btn>
              </mdb-col>
            </mdb-row>
          </mdb-col>
          <mdb-col md="4" class="md-0 mb-5">
            <mdb-row>
              <mdb-col lg="2" md="3" size="2">
                <mdb-icon icon="cogs" size="2x" class="pink-text"/>
              </mdb-col>
              <mdb-col lg="10" md="9" size="10">
                <h4 class="font-weight-bold">Customization</h4>
                <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                <mdb-btn color="pink" size="sm">Learn more</mdb-btn>
              </mdb-col>
            </mdb-row>
          </mdb-col>
          <mdb-col md="4" class="md-0 mb-5">
            <mdb-row>
              <mdb-col lg="2" md="3" size="2">
                <mdb-icon icon="tachometer-alt" size="2x" class="purple-text"/>
              </mdb-col>
              <mdb-col lg="10" md="9" size="10">
                <h4 class="font-weight-bold">Support</h4>
                <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                <mdb-btn color="purple" size="sm">Learn more</mdb-btn>
              </mdb-col>
            </mdb-row>
          </mdb-col>
        </mdb-row>
      </section>

      <section class="my-5">
        <h2 class="h1-responsive font-weight-bold text-center my-5">Why is it so great?</h2>
        <p class="lead grey-text w-responsive text-center mx-auto mb-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
        <mdb-row>
          <mdb-col lg="5" class="text-center text-lg-left">
            <img  class="img-fluid" src="https://mdbootstrap.com/img/Photos/Others/screens-section.jpg" alt="Sample image" />
          </mdb-col>
          <mdb-col lg="7">
            <mdb-row class="mb-3">
              <mdb-col size="1">
                <mdb-icon icon="share" size="lg" class="indigo-text"/>
              </mdb-col>
              <mdb-col xl="10" md="11" size="10">
                <h5 class="font-weight-bold mb-3">Safety</h5>
                <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit enim ad minima veniam, quis nostrum exercitationem ullam. Reprehenderit maiores aperiam assumenda deleniti hic.</p>
              </mdb-col>
            </mdb-row>
            <mdb-row class="mb-3">
              <mdb-col size="1">
                <mdb-icon icon="share" size="lg" class="indigo-text"/>
              </mdb-col>
              <mdb-col xl="10" md="11" size="10">
                <h5 class="font-weight-bold mb-3">Technology</h5>
                <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit enim ad minima veniam, quis nostrum exercitationem ullam. Reprehenderit maiores aperiam assumenda deleniti hic.</p>
              </mdb-col>
            </mdb-row>
            <mdb-row class="mb-3">
              <mdb-col size="1">
                <mdb-icon icon="share" size="lg" class="indigo-text"/>
              </mdb-col>
              <mdb-col xl="10" md="11" size="10">
                <h5 class="font-weight-bold mb-3">Finance</h5>
                <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit enim ad minima veniam, quis nostrum exercitationem ullam. Reprehenderit maiores aperiam assumenda deleniti hic.</p>
              </mdb-col>
            </mdb-row>
          </mdb-col>
        </mdb-row>
      </section>

      <section class="my-5">
        <h2 class="h1-responsive font-weight-bold text-center my-5">Why is it so great?</h2>
        <p class="lead grey-text w-responsive text-center mx-auto mb-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
        <mdb-row>
          <mdb-col md="4">
            <mdb-row class="mb-3">
              <mdb-col size="2">
                <mdb-icon icon="flag-checkered" size="2x" class="deep-purple-text"/>
              </mdb-col>
              <mdb-col size="10">
                <h5 class="font-weight-bold mb-3">International</h5>
                <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit maiores nam, aperiam minima assumenda deleniti hic.</p>
              </mdb-col>
            </mdb-row>
            <mdb-row class="mb-3">
              <mdb-col size="2">
                <mdb-icon icon="flask" size="2x" class="deep-purple-text"/>
              </mdb-col>
              <mdb-col size="10">
                <h5 class="font-weight-bold mb-3">Experimental</h5>
                <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit maiores nam, aperiam minima assumenda deleniti hic.</p>
              </mdb-col>
            </mdb-row>
            <mdb-row class="mb-3">
              <mdb-col size="2">
                <mdb-icon icon="glass-martini" size="2x" class="deep-purple-text"/>
              </mdb-col>
              <mdb-col size="10">
                <h5 class="font-weight-bold mb-3">Relaxing</h5>
                <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit maiores nam, aperiam minima assumenda deleniti hic.</p>
              </mdb-col>
            </mdb-row>
          </mdb-col>
          <mdb-col md="4" class="text-name">
            <img class="img-fluid" src="https://mdbootstrap.com/img/Mockups/Transparent/Small/iphone-portfolio1.png" alt="Sample image" />
          </mdb-col>
          <mdb-col md="4">
            <mdb-row class="mb-3">
              <mdb-col size="2">
                <mdb-icon icon="heart" size="2x" class="deep-purple-text"/>
              </mdb-col>
              <mdb-col size="10">
                <h5 class="font-weight-bold mb-3">Beloved</h5>
                <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit maiores nam, aperiam minima assumenda deleniti hic.</p>
              </mdb-col>
            </mdb-row>
            <mdb-row class="mb-3">
              <mdb-col size="2">
                <mdb-icon icon="bolt" size="2x" class="deep-purple-text"/>
              </mdb-col>
              <mdb-col size="10">
                <h5 class="font-weight-bold mb-3">Rapid</h5>
                <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit maiores nam, aperiam minima assumenda deleniti hic.</p>
              </mdb-col>
            </mdb-row>
            <mdb-row class="mb-3">
              <mdb-col size="2">
                <mdb-icon icon="magic" size="2x" class="deep-purple-text"/>
              </mdb-col>
              <mdb-col size="10">
                <h5 class="font-weight-bold mb-3">Magical</h5>
                <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit maiores nam, aperiam minima assumenda deleniti hic.</p>
              </mdb-col>
            </mdb-row>
          </mdb-col>
        </mdb-row>
      </section>

    </mdb-container>
  </mdb-container>
</template>

<script>
import { mdbContainer, mdbRow, mdbCol, mdbIcon, mdbBtn } from 'mdbvue';

export default {
  name: 'FeaturesPage',
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbIcon,
    mdbBtn
  },
};
</script>

<style scoped>
</style>

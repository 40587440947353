import { render, staticRenderFns } from "./ClassicFormPage.vue?vue&type=template&id=35db1d18&scoped=true&"
import script from "./ClassicFormPage.vue?vue&type=script&lang=js&"
export * from "./ClassicFormPage.vue?vue&type=script&lang=js&"
import style0 from "./ClassicFormPage.vue?vue&type=style&index=0&id=35db1d18&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35db1d18",
  null
  
)

export default component.exports
import { render, staticRenderFns } from "./ClassPage.vue?vue&type=template&id=87aec3e2&scoped=true&"
import script from "./ClassPage.vue?vue&type=script&lang=js&"
export * from "./ClassPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87aec3e2",
  null
  
)

export default component.exports
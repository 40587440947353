<template>
  <mdb-container>
    <mdb-row class="mt-5 align-items-center justify-content-start">
      <h4 class="demo-title"><strong>Blog Sections</strong></h4>
      <a href="https://mdbootstrap.com/docs/vue/sections/blog/" waves-fixed class="border grey-text px-2 border-light rounded ml-2" target="_blank"><mdb-icon icon="graduation-cap" class="mr-2"/>Docs</a>
    </mdb-row>
    <hr />
    <mdb-container>
      <h2 class="text-center text-md-left pt-4 mt-5">
        <strong>Blog listing v.1 </strong>
      </h2>

      <h2 class="h1-responsive font-weight-bold text-center my-5">Recent posts</h2>
      <p class="text-center w-responsive mx-auto mb-5">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
      <mdb-row>
        <mdb-col lg="5">
          <mdb-view class="rounded z-depth-2 mb-lg-0 mb-4" hover>
            <img class="img-fluid" src="https://mdbootstrap.com/img/Photos/Others/img%20(27).jpg" alt="Sample image" />
            <a>
              <mdb-mask overlay="white-slight" waves/>
            </a>
          </mdb-view>
        </mdb-col>
        <mdb-col lg="7">
          <a class="green-text">
            <h6 class="font-weight-bold mb-3">
              <mdb-icon icon="utensils" class="pr-2" />Food</h6>
          </a>
          <h3 class="font-weight-bold mb-3 p-0">
            <strong>Title of the news</strong>
          </h3>
          <p>Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere
            possimus, omnis voluptas assumenda est, omnis dolor repellendus et aut officiis debitis.</p>
          <p>by
            <a>
              <strong>Carine Fox</strong>
            </a>, 19/08/2018</p>
          <mdb-btn color="success" size="md" class="waves-light ">Read more</mdb-btn>
        </mdb-col>
      </mdb-row>
      <hr class="my-5" />
      <mdb-row>
        <mdb-col lg="7">
          <a class="pink-text">
            <h6 class="font-weight-bold mb-3">
              <mdb-icon icon="image" class="pr-2" />Lifestyle
            </h6>
          </a>
          <h3 class="font-weight-bold mb-3 p-0">
            <strong>Title of the news</strong>
          </h3>
          <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti
            quos dolores et quas molestias excepturi sint occaecati cupiditate non provident.</p>
          <p>by
            <a>
              <strong>Carine Fox</strong>
            </a>, 14/08/2018</p>
          <mdb-btn color="pink" size="md" class="mb-lg-0 mb-4 waves-light">Read more</mdb-btn>
        </mdb-col>
        <mdb-col lg="5">
          <mdb-view class="rounded z-depth-2 mb-lg-0 mb-4" hover>
            <img class="img-fluid" src="https://mdbootstrap.com/img/Photos/Others/img%20(34).jpg" alt="Sample image" />
            <a>
              <mdb-mask overlay="white-slight" waves/>
            </a>
          </mdb-view>
        </mdb-col>
      </mdb-row>
      <hr class="my-5" />
      <mdb-row>
        <mdb-col lg="5">
          <mdb-view class="rounded z-depth-2 mb-lg-0 mb-4" hover>
            <img class="img-fluid" src="https://mdbootstrap.com/img/Photos/Others/img (28).jpg" alt="Sample image" />
            <a>
              <mdb-mask overlay="white-slight" waves/>
            </a>
          </mdb-view>
        </mdb-col>
        <mdb-col lg="7">
          <a class="indigo-text">
            <h6 class="font-weight-bold mb-3">
              <mdb-icon icon="suitcase" class="pr-2" />Food</h6>
          </a>
          <h3 class="font-weight-bold mb-3 p-0">
            <strong>Title of the news</strong>
          </h3>
          <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos
            qui ratione voluptatem sequi nesciunt. Neque porro qui dolorem ipsum quia sit amet.</p>
          <p>by
            <a>
              <strong>Carine Fox</strong>
            </a>, 11/08/2018</p>
          <mdb-btn color="indigo" size="md" class="waves-light ">Read more</mdb-btn>
        </mdb-col>
      </mdb-row>

      <h2 class="text-center text-md-left pt-4 mt-5">
        <strong>Blog listing v.2 </strong>
      </h2>

      <h2 class="h1-responsive font-weight-bold text-center my-5">Recent posts</h2>
      <p class="text-center w-responsive mx-auto mb-5">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
      <mdb-row>
        <mdb-col lg="4" md="12" class="mb-lg-0 mb-4">
          <mdb-view hover class="rounded z-depth-2 mb-4">
            <img class="img-fluid" src="https://mdbootstrap.com/img/Photos/Others/images/81.jpg" />
            <mdb-mask overlay="white-slight" waves/>
          </mdb-view>
          <a class="pink-text">
            <h6 class="font-weight-bold mb-3">
              <mdb-icon icon="map" class="pr-2" />Adventure</h6>
          </a>
          <h4 class="font-weight-bold mb-3">
            <strong>Title of the news</strong>
          </h4>
          <p>by
            <a class="font-weight-bold">Billy Forester</a>, 15/07/2018</p>
          <p class="dark-grey-text">Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere
            possimus voluptas.</p>
          <mdb-btn color="pink" rounded size="md">Read more</mdb-btn>
        </mdb-col>
        <mdb-col lg="4" md="12" class="mb-lg-0 mb-4">
          <mdb-view hover class="rounded z-depth-2 mb-4">
            <img class="img-fluid" src="https://mdbootstrap.com/img/Photos/Others/images/43.jpg" />
            <mdb-mask overlay="white-slight" waves/>
          </mdb-view>
          <a class="deep-orange-text">
            <h6 class="font-weight-bold mb-3">
              <mdb-icon icon="graduation-cap" class="pr-2" />Education</h6>
          </a>
          <h4 class="font-weight-bold mb-3">
            <strong>Title of the news</strong>
          </h4>
          <p>by
            <a class="font-weight-bold">Billy Forester</a>, 13/07/2018</p>
          <p class="dark-grey-text">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis voluptatum deleniti atque corrupti quos dolores.</p>
          <mdb-btn color="deep-orange" rounded size="md">Read more</mdb-btn>
        </mdb-col>
        <mdb-col lg="4" md="12" class="mb-lg-0 mb-4">
          <mdb-view hover class="rounded z-depth-2 mb-4">
            <img class="img-fluid" src="https://mdbootstrap.com/img/Photos/Others/images/13.jpg" />
            <mdb-mask overlay="white-slight" waves/>
          </mdb-view>
          <a class="blue-text">
            <h6 class="font-weight-bold mb-3">
              <mdb-icon icon="fire" class="pr-2" />Culture</h6>
          </a>
          <h4 class="font-weight-bold mb-3">
            <strong>Title of the news</strong>
          </h4>
          <p>by
            <a class="font-weight-bold">Billy Forester</a>, 11/07/2018</p>
          <p class="dark-grey-text">Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos
            qui ratione.</p>
          <mdb-btn color="info" rounded size="md">Read more</mdb-btn>
        </mdb-col>
      </mdb-row>

      <h2 class="text-center text-md-left pt-4 mt-5">
        <strong>Blog listing v.3 </strong>
      </h2>

      <h2 class="h1-responsive font-weight-bold text-center my-5">Recent posts</h2>
      <p class="text-center w-responsive mx-auto mb-5">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
      <mdb-row>
        <mdb-col lg="5" xl="4">
          <mdb-view hover class="rounded z-depth-1-half mb-lg-0 mb-4">
            <img class="img-fluid" src="https://mdbootstrap.com/img/Photos/Others/images/49.jpg" alt="Sample image" />
            <a>
              <mdb-mask overlay="white-slight" waves/>
            </a>
          </mdb-view>
        </mdb-col>
        <mdb-col lg="7" xl="8">
          <h3 class="font-weight-bold mb-3 p-0">
            <strong>Title of the news</strong>
          </h3>
          <p class="dark-grey-text">Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere
            possimus, omnis voluptas assumenda est, omnis dolor repellendus et aut officiis debitis cum soluta nobis est eligendi
            placeat facere aut rerum.</p>
          <p>by
            <a class="font-weight-bold">Jessica Clark</a>, 19/04/2018</p>
          <mdb-btn color="primary" size="md">Read More</mdb-btn>
        </mdb-col>
      </mdb-row>
      <hr class="my-5" />
      <mdb-row>
        <mdb-col lg="5" xl="4">
          <mdb-view hover class="rounded z-depth-1-half mb-lg-0 mb-4">
            <img class="img-fluid" src="https://mdbootstrap.com/img/Photos/Others/images/31.jpg" alt="Sample image" />
            <a>
              <mdb-mask overlay="white-slight" waves/>
            </a>
          </mdb-view>
        </mdb-col>
        <mdb-col lg="7" xl="8">
          <h3 class="font-weight-bold mb-3 p-0">
            <strong>Title of the news</strong>
          </h3>
          <p class="dark-grey-text">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti
            quos dolores et quas molestias excepturi sint occaecati cupiditate non provident et accusamus iusto odio dignissimos
            et dolorum fuga.</p>
          <p>by
            <a class="font-weight-bold">Jessica Clark</a>, 16/04/2018</p>
          <mdb-btn color="primary" size="md">Read More</mdb-btn>
        </mdb-col>
      </mdb-row>
      <hr class="my-5" />
      <mdb-row>
        <mdb-col lg="5" xl="4">
          <mdb-view hover class="rounded z-depth-1-half mb-lg-0 mb-4">
            <img class="img-fluid" src="https://mdbootstrap.com/img/Photos/Others/images/52.jpg" alt="Sample image" />
            <a>
              <mdb-mask overlay="white-slight" waves/>
            </a>
          </mdb-view>
        </mdb-col>
        <mdb-col lg="7" xl="8">
          <h3 class="font-weight-bold mb-3 p-0">
            <strong>Title of the news</strong>
          </h3>
          <p class="dark-grey-text">Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos
            qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, psam voluptatem
            quia consectetur.</p>
          <p>by
            <a class="font-weight-bold">Jessica Clark</a>, 12/04/2018</p>
          <mdb-btn color="primary" size="md">Read More</mdb-btn>
        </mdb-col>
      </mdb-row>

      <h2 class="text-center text-md-left pt-4 mt-5">
        <strong>Blog listing v.4 </strong>
      </h2>

      <mdb-row class="mt-5">
        <mdb-col md="12">
          <mdb-card reverse>
            <mdb-view hover cascade>
              <img src="https://mdbootstrap.com/img/Photos/Slides/img%20(142).jpg" class="img-fluid" />
              <mdb-mask overlay="white-slight" class="waves-light" waves/>
            </mdb-view>
            <mdb-card-body class="text-center" cascade>
              <h2 class="font-weight-bold">
                <a>Title of the news</a>
              </h2>
              <p>Written by
                <a>
                  <strong>Abby Madison</strong>
                </a>, 26/08/2018</p>
              <mdb-btn class="btn-fb waves-light">
                <mdb-icon fab icon="facebook-f" class="pr-2"/>
                Facebook
              </mdb-btn>
              <span class="counter">46</span>
              <mdb-btn class="btn-tw waves-light">
                <mdb-icon fab icon="twitter" class="pr-2"/>
                Twitter
              </mdb-btn>
              <span class="counter">22</span>
              <mdb-btn class="btn-gplus waves-light">
                <mdb-icon fab icon="google-plus-g" class="pr-2"/>
                Google
              </mdb-btn>
              <span class="counter">31</span>
              <mdb-btn color="default" class="waves-light">
                <mdb-icon icon="comments" class="pr-2"/>
                Comments
              </mdb-btn>
              <span class="counter">18</span>
            </mdb-card-body>
          </mdb-card>
          <mdb-container class="mt-5">
            <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui praesentium voluptatum deleniti atque corrupti quos
              dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa nemo enim
              ipsam voluptatem quia voluptas sit qui officia deserunt mollitia animi, id est laborum et dolorum fuga quidem rerum
              facilis est distinctio.
            </p>
            <p>Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere
              possimus, omnis voluptas assumenda est, omnis dolor repellendus. Quis autem vel eum iure reprehenderit qui in ea
              voluptate velit esse quam nihil molestiae consequatur. Temporibus autem quibusdam et aut officiis debitis aut rerum
              necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae itaque earum rerum.
            </p>
          </mdb-container>
        </mdb-col>
      </mdb-row>
      <hr class="mb-5 mt-4" />
      <mdb-row>
        <mdb-col md="12">
          <mdb-card reverse>
            <mdb-view hover cascade>
              <img src="https://mdbootstrap.com/img/Photos/Slides/img%20(146).jpg" class="img-fluid" />
              <mdb-mask overlay="white-slight" class="waves-light" waves/>
            </mdb-view>
            <mdb-card-body class="text-center" cascade>
              <h2 class="font-weight-bold">
                <a>Title of the news</a>
              </h2>
              <p>Written by
                <a>
                  <strong>Abby Madison</strong>
                </a>, 21/08/2018</p>
              <mdb-btn class="btn-fb waves-light">
                <mdb-icon fab icon="facebook-f" class="pr-2"/>
                Facebook
              </mdb-btn>
              <span class="counter">87</span>
              <mdb-btn class="btn-tw waves-light">
                <mdb-icon fab icon="twitter" class="pr-2"/>
                Twitter
              </mdb-btn>
              <span class="counter">73</span>
              <mdb-btn class="btn-gplus waves-light">
                <mdb-icon fab icon="google-plus-g" class="pr-2"/>
                Google
              </mdb-btn>
              <span class="counter">91</span>
              <mdb-btn color="default" class="waves-light">
                <mdb-icon icon="comments" class="pr-2"/>
                Comments
              </mdb-btn>
              <span class="counter">67</span>
            </mdb-card-body>
          </mdb-card>
          <mdb-container class="mt-5">
            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
              eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
              voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
              voluptatem.
            </p>
            <p>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam
              eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis
              nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem
              vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur.
            </p>
          </mdb-container>
        </mdb-col>
      </mdb-row>

    </mdb-container>
  </mdb-container>
</template>

<script>
import { mdbContainer, mdbRow, mdbCol, mdbCard, mdbCardBody, mdbMask, mdbIcon, mdbView, mdbBtn } from 'mdbvue';

export default {
  name: 'BlogPage',
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardBody,
    mdbMask,
    mdbIcon,
    mdbView,
    mdbBtn
  }
};
</script>

<style scoped>
</style>

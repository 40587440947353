<template>
    <mdb-container>
    <mdb-row class="mt-5 align-items-center justify-content-start">
      <h4 style="margin: 0" class="grey-text" id="top"><strong>Scrollbar</strong></h4>
      <a href="https://mdbootstrap.com/docs/vue/advanced/scroll/?utm_source=DemoApp&utm_medium=MDBVuePro" waves-fixed class="border grey-text px-2 border-light rounded ml-2" target="_blank"><mdb-icon icon="graduation-cap" class="mr-2"/>Docs</a>
    </mdb-row>
    <hr class="my-4"/>
    <h2 class="font-weight-bold mb-4">Material</h2>
    <mdb-row around>
      <mdb-col class="scrollbar scrollbar-primary">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-secondary">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-default">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-info">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-success">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-warning">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-danger">
        <div class="force-overflow"></div>
      </mdb-col>
    </mdb-row>
    <hr class="my-5"/>
    <h2 class="font-weight-bold mb-4">Gradient</h2>
    <mdb-row around>
      <mdb-col class="scrollbar scrollbar-juicy-peach">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-young-passion">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-lady-lips">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-sunny-morning">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-rainy-ashville">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-frozen-dreams">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-warm-flame">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-night-fade">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-spring-warmth">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-winter-neva">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-dusty-grass">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-tempting-azure">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-heavy-rain">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-amy-crisp">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-mean-fruit">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-deep-blue">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-ripe-malinka">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-cloudy-knoxville">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-morpheus-den">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-rare-wind">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-near-moon">
        <div class="force-overflow"></div>
      </mdb-col>
    </mdb-row>
    <hr class="my-4"/>
    <h2 class="font-weight-bold mb-4">Options</h2>
    <mdb-row around>
      <mdb-col class="scrollbar square scrollbar-lady-lips thin">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar square scrollbar-lady-lips">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-black bordered-black square thin">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-black bordered-black square">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-pink bordered-pink thin">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-pink bordered-pink">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-indigo bordered-indigo thin">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-indigo bordered-indigo">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-near-moon thin">
        <div class="force-overflow"></div>
      </mdb-col>
      <mdb-col class="scrollbar scrollbar-near-moon">
        <div class="force-overflow"></div>
      </mdb-col>
    </mdb-row>
        <hr class="my-4"/>
    <h2 class="font-weight-bold mb-4">Usage with cards</h2>
    <mdb-row>
      <mdb-col md="6" class="mb-4">
        <mdb-card class="example-card scrollbar-ripe-malinka">
          <mdb-card-body>
            <h4 id="section1"><strong>First title of the news</strong></h4>
            <p>Ad leggings keytar, brunch id art party dolor labore. Pitchfork yr enim lo-fi before they sold out
              qui. Tumblr farm-to-table bicycle rights whatever. Anim keffiyeh carles cardigan. Velit seitan
              mcsweeney's photo booth 3 wolf moon irure. Cosby sweater lomo jean shorts, williamsburg hoodie minim
              qui you probably haven't heard of them et cardigan trust fund culpa biodiesel wes anderson aesthetic.
              Nihil tattooed accusamus, cred irony biodiesel keffiyeh artisan ullamco consequat.</p>
            <p>Ad leggings keytar, brunch id art party dolor labore. Pitchfork yr enim lo-fi before they sold out
              qui. Tumblr farm-to-table bicycle rights whatever. Anim keffiyeh carles cardigan. Velit seitan
              mcsweeney's photo booth 3 wolf moon irure. Cosby sweater lomo jean shorts, williamsburg hoodie minim
              qui you probably haven't heard of them et cardigan trust fund culpa biodiesel wes anderson aesthetic.
              Nihil tattooed accusamus, cred irony biodiesel keffiyeh artisan ullamco consequat.</p>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
      <mdb-col md="6" class="mb-4">
        <mdb-card class="example-card square scrollbar-cyan bordered-cyan">
          <mdb-card-body>
            <h4 id="section2"><strong>Second title of the news</strong></h4>
            <p>Ad leggings keytar, brunch id art party dolor labore. Pitchfork yr enim lo-fi before they sold out
              qui. Tumblr farm-to-table bicycle rights whatever. Anim keffiyeh carles cardigan. Velit seitan
              mcsweeney's photo booth 3 wolf moon irure. Cosby sweater lomo jean shorts, williamsburg hoodie minim
              qui you probably haven't heard of them et cardigan trust fund culpa biodiesel wes anderson aesthetic.
              Nihil tattooed accusamus, cred irony biodiesel keffiyeh artisan ullamco consequat.</p>
            <p>Ad leggings keytar, brunch id art party dolor labore. Pitchfork yr enim lo-fi before they sold out
              qui. Tumblr farm-to-table bicycle rights whatever. Anim keffiyeh carles cardigan. Velit seitan
              mcsweeney's photo booth 3 wolf moon irure. Cosby sweater lomo jean shorts, williamsburg hoodie minim
              qui you probably haven't heard of them et cardigan trust fund culpa biodiesel wes anderson aesthetic.
              Nihil tattooed accusamus, cred irony biodiesel keffiyeh artisan ullamco consequat.</p>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
      <mdb-col md="6" class="mb-4">
        <mdb-card class="example-card scrollbar-deep-purple bordered-deep-purple thin">
          <mdb-card-body>
            <h4 id="section3"><strong>Third title of the news</strong></h4>
            <p>Ad leggings keytar, brunch id art party dolor labore. Pitchfork yr enim lo-fi before they sold out
              qui. Tumblr farm-to-table bicycle rights whatever. Anim keffiyeh carles cardigan. Velit seitan
              mcsweeney's photo booth 3 wolf moon irure. Cosby sweater lomo jean shorts, williamsburg hoodie minim
              qui you probably haven't heard of them et cardigan trust fund culpa biodiesel wes anderson aesthetic.
              Nihil tattooed accusamus, cred irony biodiesel keffiyeh artisan ullamco consequat.</p>
            <p>Ad leggings keytar, brunch id art party dolor labore. Pitchfork yr enim lo-fi before they sold out
              qui. Tumblr farm-to-table bicycle rights whatever. Anim keffiyeh carles cardigan. Velit seitan
              mcsweeney's photo booth 3 wolf moon irure. Cosby sweater lomo jean shorts, williamsburg hoodie minim
              qui you probably haven't heard of them et cardigan trust fund culpa biodiesel wes anderson aesthetic.
              Nihil tattooed accusamus, cred irony biodiesel keffiyeh artisan ullamco consequat.</p>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
      <mdb-col md="6" class="mb-4">
        <mdb-card class="example-card square scrollbar-dusty-grass square thin">
          <mdb-card-body>
            <h4 id="section4"><strong>Fourth title of the news</strong></h4>
            <p>Ad leggings keytar, brunch id art party dolor labore. Pitchfork yr enim lo-fi before they sold out
              qui. Tumblr farm-to-table bicycle rights whatever. Anim keffiyeh carles cardigan. Velit seitan
              mcsweeney's photo booth 3 wolf moon irure. Cosby sweater lomo jean shorts, williamsburg hoodie minim
              qui you probably haven't heard of them et cardigan trust fund culpa biodiesel wes anderson aesthetic.
              Nihil tattooed accusamus, cred irony biodiesel keffiyeh artisan ullamco consequat.</p>
            <p>Ad leggings keytar, brunch id art party dolor labore. Pitchfork yr enim lo-fi before they sold out
              qui. Tumblr farm-to-table bicycle rights whatever. Anim keffiyeh carles cardigan. Velit seitan
              mcsweeney's photo booth 3 wolf moon irure. Cosby sweater lomo jean shorts, williamsburg hoodie minim
              qui you probably haven't heard of them et cardigan trust fund culpa biodiesel wes anderson aesthetic.
              Nihil tattooed accusamus, cred irony biodiesel keffiyeh artisan ullamco consequat.</p>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
    </mdb-row>
    <h2 class="font-weight-bold mb-4">Perfect scroll for images</h2>
      <mdb-scrollbar height="30vw" width="60vw">
        <img src="https://mdbootstrap.com/img/Photos/Others/food3.jpg"/>
      </mdb-scrollbar>
  </mdb-container>
</template>

<script>
import { mdbContainer, mdbRow, mdbIcon, mdbCol, mdbCard, mdbCardBody, mdbScrollbar } from 'mdbvue';
export default {
  name: "SmoothScrollPage",
  components: {
    mdbContainer,
    mdbRow,
    mdbIcon,
    mdbCol,
    mdbCard,
    mdbCardBody,
    mdbScrollbar
  }
};
</script>

<style scoped>

.force-overflow {
  min-height: 450px;
}

.scrollbar {
  float: left;
  height: 300px;
  width: 150px;
  background: #fff;
  overflow-y: scroll;
  margin-bottom: 25px;
}

.scrollbar-primary::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #4285F4;
}

.scrollbar-danger::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-danger::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-danger::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #ff3547;
}

.scrollbar-warning::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-warning::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-warning::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #FF8800;
}

.scrollbar-success::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-success::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-success::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #00C851;
}

.scrollbar-info::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-info::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-info::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #33b5e5;
}

.scrollbar-default::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-default::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-default::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #2BBBAD;
}

.scrollbar-secondary::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-secondary::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-secondary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #aa66cc;
}

/*
    G R A D I E N T S
*/

.scrollbar-juicy-peach::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-juicy-peach::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-juicy-peach::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(linear, left top, right top, from(#ffecd2), to(#fcb69f));
  background-image: -webkit-linear-gradient(left, #ffecd2 0%, #fcb69f 100%);
  background-image: linear-gradient(to bottom, #ffecd2 0%, #fcb69f 100%);
}

.scrollbar-young-passion::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-young-passion::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-young-passion::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(linear, left top, right top, from(#ff8177), color-stop(0%, #ff867a),
    color-stop(21%, #ff8c7f), color-stop(52%, #f99185), color-stop(78%, #cf556c), to(#b12a5b));
  background-image: -webkit-linear-gradient(left, #ff8177 0%, #ff867a 0%, #ff8c7f 21%, #f99185 52%, #cf556c 78%,
    #b12a5b 100%);
  background-image: linear-gradient(to bottom, #ff8177 0%, #ff867a 0%, #ff8c7f 21%, #f99185 52%, #cf556c 78%,
    #b12a5b 100%);
}

.scrollbar-lady-lips::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-lady-lips::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-lady-lips::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(linear, left bottom, left top, from(#ff9a9e), color-stop(99%, #fecfef),
    to(#fecfef));
  background-image: -webkit-linear-gradient(bottom, #ff9a9e 0%, #fecfef 99%, #fecfef 100%);
  background-image: linear-gradient(to top, #ff9a9e 0%, #fecfef 99%, #fecfef 100%);
}

.scrollbar-sunny-morning::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-sunny-morning::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-sunny-morning::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-linear-gradient(330deg, #f6d365 0%, #fda085 100%);
  background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
}

.scrollbar-rainy-ashville::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-rainy-ashville::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-rainy-ashville::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(linear, left bottom, left top, from(#fbc2eb), to(#a6c1ee));
  background-image: -webkit-linear-gradient(bottom, #fbc2eb 0%, #a6c1ee 100%);
  background-image: linear-gradient(to top, #fbc2eb 0%, #a6c1ee 100%);
}

.scrollbar-frozen-dreams::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-frozen-dreams::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-frozen-dreams::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(linear, left bottom, left top, from(#fdcbf1), color-stop(1%, #fdcbf1),
    to(#e6dee9));
  background-image: -webkit-linear-gradient(bottom, #fdcbf1 0%, #fdcbf1 1%, #e6dee9 100%);
  background-image: linear-gradient(to top, #fdcbf1 0%, #fdcbf1 1%, #e6dee9 100%);
}

.scrollbar-warm-flame::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-warm-flame::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-warm-flame::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%);
  background-image: linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%);
}

.scrollbar-night-fade::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-night-fade::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-night-fade::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(linear, left bottom, left top, from(#a18cd1), to(#fbc2eb));
  background-image: -webkit-linear-gradient(bottom, #a18cd1 0%, #fbc2eb 100%);
  background-image: linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%);
}

.scrollbar-spring-warmth::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-spring-warmth::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-spring-warmth::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(linear, left bottom, left top, from(#fad0c4), to(#ffd1ff));
  background-image: -webkit-linear-gradient(bottom, #fad0c4 0%, #ffd1ff 100%);
  background-image: linear-gradient(to top, #fad0c4 0%, #ffd1ff 100%);
}

.scrollbar-winter-neva::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-winter-neva::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-winter-neva::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-linear-gradient(330deg, #a1c4fd 0%, #c2e9fb 100%);
  background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
}

.scrollbar-dusty-grass::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-dusty-grass::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-dusty-grass::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-linear-gradient(330deg, #d4fc79 0%, #96e6a1 100%);
  background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
}

.scrollbar-tempting-azure::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-tempting-azure::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-tempting-azure::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-linear-gradient(330deg, #84fab0 0%, #8fd3f4 100%);
  background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
}

.scrollbar-heavy-rain::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-heavy-rain::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-heavy-rain::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(linear, left bottom, left top, from(#cfd9df), to(#e2ebf0));
  background-image: -webkit-linear-gradient(bottom, #cfd9df 0%, #e2ebf0 100%);
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
}

.scrollbar-amy-crisp::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-amy-crisp::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-amy-crisp::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-linear-gradient(330deg, #a6c0fe 0%, #f68084 100%);
  background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%);
}

.scrollbar-mean-fruit::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-mean-fruit::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-mean-fruit::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-linear-gradient(330deg, #fccb90 0%, #d57eeb 100%);
  background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
}

.scrollbar-deep-blue::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-deep-blue::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-deep-blue::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-linear-gradient(330deg, #e0c3fc 0%, #8ec5fc 100%);
  background-image: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%);
}

.scrollbar-ripe-malinka::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-ripe-malinka::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-ripe-malinka::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-linear-gradient(330deg, #f093fb 0%, #f5576c 100%);
  background-image: linear-gradient(120deg, #f093fb 0%, #f5576c 100%);
}

.scrollbar-cloudy-knoxville::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-cloudy-knoxville::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-cloudy-knoxville::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-linear-gradient(330deg, #fdfbfb 0%, #ebedee 100%);
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}

.scrollbar-morpheus-den::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-morpheus-den::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-morpheus-den::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(linear, left bottom, left top, from(#30cfd0), to(#330867));
  background-image: -webkit-linear-gradient(bottom, #30cfd0 0%, #330867 100%);
  background-image: linear-gradient(to top, #30cfd0 0%, #330867 100%);
}

.scrollbar-rare-wind::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-rare-wind::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-rare-wind::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(linear, left bottom, left top, from(#a8edea), to(#fed6e3));
  background-image: -webkit-linear-gradient(bottom, #a8edea 0%, #fed6e3 100%);
  background-image: linear-gradient(to top, #a8edea 0%, #fed6e3 100%);
}

.scrollbar-near-moon::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-near-moon::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-near-moon::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(linear, left bottom, left top, from(#5ee7df), to(#b490ca));
  background-image: -webkit-linear-gradient(bottom, #5ee7df 0%, #b490ca 100%);
  background-image: linear-gradient(to top, #5ee7df 0%, #b490ca 100%);
}

/*
    O P T I O N S
*/

.scrollbar-pink::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-pink::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-pink::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #ec407a;
}

.scrollbar-indigo::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-indigo::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-indigo::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #3f51b5;
}

.scrollbar-black::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-black::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-black::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #000;
}

.scrollbar-lady-lips::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-lady-lips::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-lady-lips::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(linear, left bottom, left top, from(#ff9a9e), color-stop(99%, #fecfef),
    to(#fecfef));
  background-image: -webkit-linear-gradient(bottom, #ff9a9e 0%, #fecfef 99%, #fecfef 100%);
  background-image: linear-gradient(to top, #ff9a9e 0%, #fecfef 99%, #fecfef 100%);
}

.scrollbar-near-moon::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-near-moon::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-near-moon::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(linear, left bottom, left top, from(#5ee7df), to(#b490ca));
  background-image: -webkit-linear-gradient(bottom, #5ee7df 0%, #b490ca 100%);
  background-image: linear-gradient(to top, #5ee7df 0%, #b490ca 100%);
}

.bordered-pink::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border: 1px solid #ec407a;
}

.bordered-pink::-webkit-scrollbar-thumb {
  -webkit-box-shadow: none;
}

.bordered-indigo::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border: 1px solid #3f51b5;
}

.bordered-indigo::-webkit-scrollbar-thumb {
  -webkit-box-shadow: none;
}

.bordered-black::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border: 1px solid #000;
}

.bordered-black::-webkit-scrollbar-thumb {
  -webkit-box-shadow: none;
}

.square::-webkit-scrollbar-track {
  border-radius: 0 !important;
}

.square::-webkit-scrollbar-thumb {
  border-radius: 0 !important;
}

.thin::-webkit-scrollbar {
  width: 6px;
}

/*
  C A R D S
*/

.scrollbar-deep-purple::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-deep-purple::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-deep-purple::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #512da8;
}

.scrollbar-cyan::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-cyan::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-cyan::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #00bcd4;
}

.scrollbar-dusty-grass::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-dusty-grass::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-dusty-grass::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-linear-gradient(330deg, #d4fc79 0%, #96e6a1 100%);
  background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
}

.scrollbar-ripe-malinka::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-ripe-malinka::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-ripe-malinka::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-linear-gradient(330deg, #f093fb 0%, #f5576c 100%);
  background-image: linear-gradient(120deg, #f093fb 0%, #f5576c 100%);
}

.bordered-deep-purple::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border: 1px solid #512da8;
}

.bordered-deep-purple::-webkit-scrollbar-thumb {
  -webkit-box-shadow: none;
}

.bordered-cyan::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border: 1px solid #00bcd4;
}

.bordered-cyan::-webkit-scrollbar-thumb {
  -webkit-box-shadow: none;
}

.square::-webkit-scrollbar-track {
  border-radius: 0 !important;
}

.square::-webkit-scrollbar-thumb {
  border-radius: 0 !important;
}

.thin::-webkit-scrollbar {
  width: 6px;
}


.example-card {
  position: relative;
  overflow-y: scroll;
  height: 200px;
}
</style>

<template>
  <mdb-container>
    <mdb-row class="mt-5 align-items-center justify-content-start">
      <h4 class="demo-title"><strong>Hamburger Menu</strong></h4>
      <a href="https://mdbootstrap.com/docs/vue/navigation/hamburger-menu/?utm_source=DemoApp&utm_medium=MDBVueFree" waves-fixed class="border grey-text px-2 border-light rounded ml-2" target="_blank"><mdb-icon icon="graduation-cap" class="mr-2"/>Docs</a>
    </mdb-row>
    <section class="demo-section">
      <h4 class="my-4">Hamburger menu basic</h4>
      <section>
        <mdb-navbar class="mt-2" hamburger expand="xl">
          <mdb-navbar-brand>
            Navbar
          </mdb-navbar-brand>
          <mdb-navbar-toggler>
            <mdb-navbar-nav right>
              <mdb-nav-item href="#" waves-fixed>Home</mdb-nav-item>
              <mdb-nav-item href="#" waves-fixed>Features</mdb-nav-item>
              <mdb-nav-item href="#" waves-fixed>Pricing</mdb-nav-item>
            </mdb-navbar-nav>
          </mdb-navbar-toggler>
        </mdb-navbar>
        <mdb-navbar color="light-blue" class="mt-2 lighten-4" hamburger navIconClass="dark-blue-text" expand="xl">
          <mdb-navbar-brand>
            Navbar
          </mdb-navbar-brand>
          <mdb-navbar-toggler>
            <mdb-navbar-nav right>
              <mdb-nav-item href="#" waves-fixed>Home</mdb-nav-item>
              <mdb-nav-item href="#" waves-fixed>Features</mdb-nav-item>
              <mdb-nav-item href="#" waves-fixed>Pricing</mdb-nav-item>
            </mdb-navbar-nav>
          </mdb-navbar-toggler>
        </mdb-navbar>
        <mdb-navbar color="pink" class="mt-2 lighten-4" hamburger navIconClass="dark-pink-text" expand="xl">
          <mdb-navbar-brand>
            Navbar
          </mdb-navbar-brand>
          <mdb-navbar-toggler>
            <mdb-navbar-nav right>
              <mdb-nav-item href="#" waves-fixed>Home</mdb-nav-item>
              <mdb-nav-item href="#" waves-fixed>Features</mdb-nav-item>
              <mdb-nav-item href="#" waves-fixed>Pricing</mdb-nav-item>
            </mdb-navbar-nav>
          </mdb-navbar-toggler>
        </mdb-navbar>
        <mdb-navbar color="amber" class="mt-2 lighten-4" hamburger navIconClass="dark-amber-text" expand="xl">
          <mdb-navbar-brand>
            Navbar
          </mdb-navbar-brand>
          <mdb-navbar-toggler>
            <mdb-navbar-nav right>
              <mdb-nav-item href="#" waves-fixed>Home</mdb-nav-item>
              <mdb-nav-item href="#" waves-fixed>Features</mdb-nav-item>
              <mdb-nav-item href="#" waves-fixed>Pricing</mdb-nav-item>
            </mdb-navbar-nav>
          </mdb-navbar-toggler>
        </mdb-navbar>
        <mdb-navbar color="teal" class="mt-2 lighten-4" hamburger navIconClass="dark-teal-text" expand="xl">
          <mdb-navbar-brand>
            Navbar
          </mdb-navbar-brand>
          <mdb-navbar-toggler>
            <mdb-navbar-nav right>
              <mdb-nav-item href="#" waves-fixed>Home</mdb-nav-item>
              <mdb-nav-item href="#" waves-fixed>Features</mdb-nav-item>
              <mdb-nav-item href="#" waves-fixed>Pricing</mdb-nav-item>
            </mdb-navbar-nav>
          </mdb-navbar-toggler>
        </mdb-navbar>
      </section>
    </section>
    <section class="demo-section">
      <h4 class="my-4">Various hamburger menu icons</h4>
      <section>
        <mdb-navbar class="mt-2 navbar-1" expand="xl">
          <mdb-navbar-brand>
            Navbar
          </mdb-navbar-brand>
          <mdb-navbar-toggler>
            <mdb-navbar-nav right>
              <mdb-nav-item href="#" waves-fixed>Home</mdb-nav-item>
              <mdb-nav-item href="#" waves-fixed>Features</mdb-nav-item>
              <mdb-nav-item href="#" waves-fixed>Pricing</mdb-nav-item>
            </mdb-navbar-nav>
          </mdb-navbar-toggler>
        </mdb-navbar>
        <mdb-navbar class="mt-2 navbar-2" dark color="primary" expand="xl">
          <mdb-navbar-brand class="text-white">
            Navbar
          </mdb-navbar-brand>
          <mdb-navbar-toggler>
            <mdb-navbar-nav right>
              <mdb-nav-item href="#" waves-fixed>Home</mdb-nav-item>
              <mdb-nav-item href="#" waves-fixed>Features</mdb-nav-item>
              <mdb-nav-item href="#" waves-fixed>Pricing</mdb-nav-item>
            </mdb-navbar-nav>
          </mdb-navbar-toggler>
        </mdb-navbar>
        <mdb-navbar class="mt-2 navbar-3" dark color="default" expand="xl">
          <mdb-navbar-brand class="text-white">
            Navbar
          </mdb-navbar-brand>
          <mdb-navbar-toggler>
            <mdb-navbar-nav right>
              <mdb-nav-item href="#" waves-fixed>Home</mdb-nav-item>
              <mdb-nav-item href="#" waves-fixed>Features</mdb-nav-item>
              <mdb-nav-item href="#" waves-fixed>Pricing</mdb-nav-item>
            </mdb-navbar-nav>
          </mdb-navbar-toggler>
        </mdb-navbar>
        <mdb-navbar color="pink" class="mt-2 navbar-4" dark expand="xl">
          <mdb-navbar-brand class="text-white">
            Navbar
          </mdb-navbar-brand>
          <mdb-navbar-toggler>
            <mdb-navbar-nav right>
              <mdb-nav-item href="#" waves-fixed>Home</mdb-nav-item>
              <mdb-nav-item href="#" waves-fixed>Features</mdb-nav-item>
              <mdb-nav-item href="#" waves-fixed>Pricing</mdb-nav-item>
            </mdb-navbar-nav>
          </mdb-navbar-toggler>
        </mdb-navbar>
        <mdb-navbar class="mt-2 navbar-5" dark color="secondary" expand="xl">
          <mdb-navbar-brand class="text-white">
            Navbar
          </mdb-navbar-brand>
          <mdb-navbar-toggler>
            <mdb-navbar-nav right>
              <mdb-nav-item href="#" waves-fixed>Home</mdb-nav-item>
              <mdb-nav-item href="#" waves-fixed>Features</mdb-nav-item>
              <mdb-nav-item href="#" waves-fixed>Pricing</mdb-nav-item>
            </mdb-navbar-nav>
          </mdb-navbar-toggler>
        </mdb-navbar>
        <mdb-navbar class="mt-2 navbar-6" dark color="success" expand="xl">
          <mdb-navbar-brand class="text-white">
            Navbar
          </mdb-navbar-brand>
          <mdb-navbar-toggler>
            <mdb-navbar-nav right>
              <mdb-nav-item href="#" waves-fixed>Home</mdb-nav-item>
              <mdb-nav-item href="#" waves-fixed>Features</mdb-nav-item>
              <mdb-nav-item href="#" waves-fixed>Pricing</mdb-nav-item>
            </mdb-navbar-nav>
          </mdb-navbar-toggler>
        </mdb-navbar>
        <mdb-navbar class="mt-2 navbar-7" dark color="info" expand="xl">
          <mdb-navbar-brand class="text-white">
            Navbar
          </mdb-navbar-brand>
          <mdb-navbar-toggler>
            <mdb-navbar-nav right>
              <mdb-nav-item href="#" waves-fixed>Home</mdb-nav-item>
              <mdb-nav-item href="#" waves-fixed>Features</mdb-nav-item>
              <mdb-nav-item href="#" waves-fixed>Pricing</mdb-nav-item>
            </mdb-navbar-nav>
          </mdb-navbar-toggler>
        </mdb-navbar>
        <mdb-navbar class="mt-2 navbar-8" dark color="warning" expand="xl">
          <mdb-navbar-brand class="text-white">
            Navbar
          </mdb-navbar-brand>
          <mdb-navbar-toggler>
            <mdb-navbar-nav right>
              <mdb-nav-item href="#" waves-fixed>Home</mdb-nav-item>
              <mdb-nav-item href="#" waves-fixed>Features</mdb-nav-item>
              <mdb-nav-item href="#" waves-fixed>Pricing</mdb-nav-item>
            </mdb-navbar-nav>
          </mdb-navbar-toggler>
        </mdb-navbar>
        <mdb-navbar class="mt-2 navbar-9" dark color="danger" expand="xl">
          <mdb-navbar-brand class="text-white">
            Navbar
          </mdb-navbar-brand>
          <mdb-navbar-toggler>
            <mdb-navbar-nav right>
              <mdb-nav-item href="#" waves-fixed>Home</mdb-nav-item>
              <mdb-nav-item href="#" waves-fixed>Features</mdb-nav-item>
              <mdb-nav-item href="#" waves-fixed>Pricing</mdb-nav-item>
            </mdb-navbar-nav>
          </mdb-navbar-toggler>
        </mdb-navbar>
      </section>
    </section>
    <section class="demo-section">
      <h4>Custom hamburger menu icons</h4>
      <section>
        <mdb-container>
          <!--Navbar-->
          <mdb-navbar expand="xl" class="mt-2 grey" hamburger dark togglerIcon="caret-square-down">
            <!-- Navbar brand -->
            <mdb-navbar-brand class="text-white">Navbar</mdb-navbar-brand>
            <mdb-navbar-toggler>
              <mdb-navbar-nav right>
                <mdb-nav-item href="#" waves-fixed>Home</mdb-nav-item>
                <mdb-nav-item href="#" waves-fixed>Features</mdb-nav-item>
                <mdb-nav-item href="#" waves-fixed>Pricing</mdb-nav-item>
              </mdb-navbar-nav>
            </mdb-navbar-toggler>
          </mdb-navbar>
          <!--/.Navbar-->
          <!--Navbar-->
          <mdb-navbar expand="xl" class="mt-2" hamburger togglerIcon="heart" dark color="indigo" iconColor="white">
            <!-- Navbar brand -->
            <mdb-navbar-brand class="text-white">Navbar</mdb-navbar-brand>
            <mdb-navbar-toggler>
              <mdb-navbar-nav right>
                <mdb-nav-item href="#" waves-fixed>Home</mdb-nav-item>
                <mdb-nav-item href="#" waves-fixed>Features</mdb-nav-item>
                <mdb-nav-item href="#" waves-fixed>Pricing</mdb-nav-item>
              </mdb-navbar-nav>
            </mdb-navbar-toggler>
          </mdb-navbar>
          <!--/.Navbar-->
          <!--Navbar-->
          <mdb-navbar expand="xl" class="mt-2" hamburger togglerIcon="paper-plane" far dark color="mdb-color" iconColor="white">
            <!-- Navbar brand -->
            <mdb-navbar-brand class="text-white">Navbar</mdb-navbar-brand>
            <mdb-navbar-toggler>
              <mdb-navbar-nav right>
                <mdb-nav-item href="#" waves-fixed>Home</mdb-nav-item>
                <mdb-nav-item href="#" waves-fixed>Features</mdb-nav-item>
                <mdb-nav-item href="#" waves-fixed>Pricing</mdb-nav-item>
              </mdb-navbar-nav>
            </mdb-navbar-toggler>
          </mdb-navbar>
          <!--/.Navbar-->
          <!--Navbar-->
          <mdb-navbar expand="xl" class="mt-2" hamburger togglerIcon="angle-double-down" dark color="info" iconColor="white">
            <!-- Navbar brand -->
            <mdb-navbar-brand class="text-white">Navbar</mdb-navbar-brand>
            <mdb-navbar-toggler>
              <mdb-navbar-nav right>
                <mdb-nav-item href="#" waves-fixed>Home</mdb-nav-item>
                <mdb-nav-item href="#" waves-fixed>Features</mdb-nav-item>
                <mdb-nav-item href="#" waves-fixed>Pricing</mdb-nav-item>
              </mdb-navbar-nav>
            </mdb-navbar-toggler>
          </mdb-navbar>
          <!--/.Navbar-->
          <!--Navbar-->
          <mdb-navbar expand="xl" class="mt-2 darken-4" hamburger togglerIcon="chevron-down" togglerSize="xs" dark color="amber" iconColor="white">
            <!-- Navbar brand -->
            <mdb-navbar-brand class="text-white">Navbar</mdb-navbar-brand>
            <mdb-navbar-toggler>
              <mdb-navbar-nav right>
                <mdb-nav-item href="#" waves-fixed>Home</mdb-nav-item>
                <mdb-nav-item href="#" waves-fixed>Features</mdb-nav-item>
                <mdb-nav-item href="#" waves-fixed>Pricing</mdb-nav-item>
              </mdb-navbar-nav>
            </mdb-navbar-toggler>
          </mdb-navbar>
          <!--/.Navbar-->
          <!--Navbar-->
          <mdb-navbar expand="xl" hamburger  class="mt-2" togglerIcon="plus" dark color="danger" iconColor="white">
            <!-- Navbar brand -->
            <mdb-navbar-brand class="text-white">Navbar</mdb-navbar-brand>
            <mdb-navbar-toggler>
              <mdb-navbar-nav right>
                <mdb-nav-item href="#" waves-fixed>Home</mdb-nav-item>
                <mdb-nav-item href="#" waves-fixed>Features</mdb-nav-item>
                <mdb-nav-item href="#" waves-fixed>Pricing</mdb-nav-item>
              </mdb-navbar-nav>
            </mdb-navbar-toggler>
          </mdb-navbar>
          <!--/.Navbar-->
        </mdb-container>
      </section>
    </section>
    <section class="demo-section">
      <h4>Hamburger menu icon animations</h4>
      <section>
        <mdb-container>
          <!--Navbar-->
          <mdb-navbar color="amber" class="mt-2 lighten-4" expand="xl" togglerIcon="" animated animation="1">
            <!-- Navbar brand -->
            <mdb-navbar-brand>Navbar</mdb-navbar-brand>
            <mdb-navbar-toggler>
              <mdb-navbar-nav right>
                <mdb-nav-item href="#" waves-fixed>Home</mdb-nav-item>
                <mdb-nav-item href="#" waves-fixed>Features</mdb-nav-item>
                <mdb-nav-item href="#" waves-fixed>Pricing</mdb-nav-item>
              </mdb-navbar-nav>
            </mdb-navbar-toggler>
          </mdb-navbar>
          <!--/.Navbar-->
          <!--Navbar-->
          <mdb-navbar color="red" class="mt-2 lighten-1" expand="xl" dark animated animation="3">
            <!-- Navbar brand -->
            <mdb-navbar-brand class="text-white">Navbar</mdb-navbar-brand>
            <mdb-navbar-toggler>
              <mdb-navbar-nav right>
                <mdb-nav-item href="#" waves-fixed>Home</mdb-nav-item>
                <mdb-nav-item href="#" waves-fixed>Features</mdb-nav-item>
                <mdb-nav-item href="#" waves-fixed>Pricing</mdb-nav-item>
              </mdb-navbar-nav>
            </mdb-navbar-toggler>
          </mdb-navbar>
          <!--/.Navbar-->
          <!--Navbar-->
          <mdb-navbar color="indigo" class="mt-2" expand="xl" dark animated animation="2">
            <!-- Navbar brand -->
            <mdb-navbar-brand class="text-white">Navbar</mdb-navbar-brand>
            <mdb-navbar-toggler>
              <mdb-navbar-nav right>
                <mdb-nav-item href="#" waves-fixed>Home</mdb-nav-item>
                <mdb-nav-item href="#" waves-fixed>Features</mdb-nav-item>
                <mdb-nav-item href="#" waves-fixed>Pricing</mdb-nav-item>
              </mdb-navbar-nav>
            </mdb-navbar-toggler>
          </mdb-navbar>
        </mdb-container>
      </section>
    </section>
  </mdb-container>
</template>

<script>
import { mdbNavbar, mdbNavItem, mdbNavbarNav, mdbNavbarToggler, mdbContainer, mdbRow, mdbNavbarBrand, mdbIcon } from 'mdbvue';

export default {
  name: 'HamburgerMenuPage',
  components: {
    mdbNavbar,
    mdbNavItem,
    mdbNavbarNav,
    mdbNavbarToggler,
    mdbContainer,
    mdbRow,
    mdbNavbarBrand,
    mdbIcon
  }
};
</script>

<style>
.dark-blue-text {
  color: #0A38F5;
}
.dark-pink-text {
  color: #AC003A;
}
.dark-amber-text {
  color: #ff6f00;
}
.dark-teal-text {
  color: #004d40;
}
.navbar.navbar-1 .navbar-toggler-icon {
  background-image: url('https://mdbootstrap.com/img/svg/hamburger6.svg?color=000');
}
.navbar.navbar-2 .navbar-toggler-icon {
  background-image: url('https://mdbootstrap.com/img/svg/hamburger2.svg?color=fff');
}
.navbar.navbar-3 .navbar-toggler-icon {
  background-image: url('https://mdbootstrap.com/img/svg/hamburger1.svg?color=6a1b9a');
}
.navbar.navbar-4 .navbar-toggler-icon {
  background-image: url('https://mdbootstrap.com/img/svg/hamburger7.svg?color=BFE100');
}
.navbar.navbar-5 .navbar-toggler-icon {
  background-image: url('https://mdbootstrap.com/img/svg/hamburger5.svg?color=f3e5f5');
}
.navbar.navbar-6 .navbar-toggler-icon {
  background-image: url('https://mdbootstrap.com/img/svg/hamburger8.svg?color=E3005C');
}
.navbar.navbar-7 .navbar-toggler-icon {
  background-image: url('https://mdbootstrap.com/img/svg/hamburger9.svg?color=FF2C00');
}
.navbar.navbar-8 .navbar-toggler-icon {
  background-image: url('https://mdbootstrap.com/img/svg/hamburger4.svg?color=1729B0');
}
.navbar.navbar-9 .navbar-toggler-icon {
  background-image: url('https://mdbootstrap.com/img/svg/hamburger3.svg?color=00FBD8');
}
</style>

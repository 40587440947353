<template>
  <mdb-container>
    <mdb-row class="mt-5 align-items-center justify-content-start">
      <h4 class="demo-title"><strong>Project Sections</strong></h4>
      <a href="https://mdbootstrap.com/docs/vue/sections/projects/" waves-fixed class="border grey-text px-2 border-light rounded ml-2" target="_blank"><mdb-icon icon="graduation-cap" class="mr-2"/>Docs</a>
    </mdb-row>
    <hr />
    <mdb-container>
      <section class="text-center my-5">
        <h2 class="h1-responsive font-weight-bold my-5">Our best projects</h2>
        <p class="grey-text w-responsive mx-auto mb-5">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit est laborum.</p>
        <mdb-row class="text-center">
          <mdb-col lg="4" md="12" class="mb-lg-0 mb-4">
            <mdb-view class="overlay rounded z-depth-1">
              <img src="https://mdbootstrap.com/img/Photos/Others/images/58.jpg" alt="sample photo" class="img-fluid"/>
              <a>
                <mdb-mask waves overlay="white-slight"/>
              </a>
            </mdb-view>
            <mdb-card-body class="pb-0">
              <h4 class="font-weight-bold my-3">Title of the news</h4>
              <p class="grey-text">Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae.</p>
              <mdb-btn color="indigo" size="sm"><mdb-icon icon="clone" class="left"/> View project</mdb-btn>
            </mdb-card-body>
          </mdb-col>
          <mdb-col lg="4" md="12" class="mb-lg-0 mb-4">
            <mdb-view class="overlay rounded z-depth-1">
              <img src="https://mdbootstrap.com/img/Photos/Others/project4.jpg" alt="sample photo" class="img-fluid"/>
              <a>
                <mdb-mask waves overlay="white-slight"/>
              </a>
            </mdb-view>
            <mdb-card-body class="pb-0">
              <h4 class="font-weight-bold my-3">Title of the news</h4>
              <p class="grey-text">Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae.</p>
              <mdb-btn color="indigo" size="sm"><mdb-icon icon="clone" class="left"/> View project</mdb-btn>
            </mdb-card-body>
          </mdb-col>
          <mdb-col lg="4" md="12" class="mb-lg-0 mb-4">
            <mdb-view class="overlay rounded z-depth-1">
              <img src="https://mdbootstrap.com/img/Photos/Others/images/88.jpg" alt="sample photo" class="img-fluid"/>
              <a>
                <mdb-mask waves overlay="white-slight"/>
              </a>
            </mdb-view>
            <mdb-card-body class="pb-0">
              <h4 class="font-weight-bold my-3">Title of the news</h4>
              <p class="grey-text">Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae.</p>
              <mdb-btn color="indigo" size="sm"><mdb-icon icon="clone" class="left"/> View project</mdb-btn>
            </mdb-card-body>
          </mdb-col>
        </mdb-row>
      </section>

      <section class="text-center my-5">
        <h2 class="h1-responsive font-weight-bold my-5">Our best projects</h2>
        <p class="grey-text w-responsive mx-auto mb-5">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit est laborum.</p>
        <mdb-row class="d-flex justify-content-center">
          <mdb-col md="6" xl="5" class="mb-4">
            <mdb-view class="overlay rounded z-depth-2">
              <img src="https://mdbootstrap.com/img/Photos/Others/laptop-sm.jpg" alt="sample photo" class="img-fluid"/>
              <a>
                <mdb-mask waves overlay="white-slight"/>
              </a>
            </mdb-view>
            <mdb-card-body class="pb-0">
              <a href="" class="green-text"><h5 class="font-weight-bold mt-2 mb-3"><mdb-icon icon="chart-line" class="pr-2"/>Marketing</h5></a>
              <h4 class="font-weight-bold mb-3">Title of the news</h4>
              <p>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit cupidatat proident voluptatem quia numquam.</p>
              <mdb-btn color="success" rounded>View more</mdb-btn>
            </mdb-card-body>
          </mdb-col>
          <mdb-col md="6" xl="5" class="mb-4">
            <mdb-view class="overlay rounded z-depth-2">
              <img src="https://mdbootstrap.com/img/Photos/Others/images/19.jpg" alt="sample photo" class="img-fluid"/>
              <a>
                <mdb-mask waves overlay="white-slight"/>
              </a>
            </mdb-view>
            <mdb-card-body class="pb-0">
              <a href="" class="blue-text"><h5 class="font-weight-bold mt-2 mb-3"><mdb-icon icon="eye" class="pr-2"/>Entertainment</h5></a>
              <h4 class="font-weight-bold mb-3">Title of the news</h4>
              <p>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit cupidatat proident voluptatem quia numquam.</p>
              <mdb-btn color="success" rounded>View more</mdb-btn>
            </mdb-card-body>
          </mdb-col>
          <mdb-col md="6" xl="5" class="mb-4">
            <mdb-view class="overlay rounded z-depth-2">
              <img src="https://mdbootstrap.com/img/Photos/Others/images/48.jpg" alt="sample photo" class="img-fluid"/>
              <a>
                <mdb-mask waves overlay="white-slight"/>
              </a>
            </mdb-view>
            <mdb-card-body class="pb-0">
              <a href="" class="brown-text"><h5 class="font-weight-bold mt-2 mb-3"><mdb-icon icon="camera" class="pr-2"/>Travel</h5></a>
              <h4 class="font-weight-bold mb-3">Title of the news</h4>
              <p>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit cupidatat proident voluptatem quia numquam.</p>
              <mdb-btn color="success" rounded>View more</mdb-btn>
            </mdb-card-body>
          </mdb-col>
          <mdb-col md="6" xl="5" class="mb-4">
            <mdb-view class="overlay rounded z-depth-2">
              <img src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Products/img (56).jpg" alt="sample photo" class="img-fluid"/>
              <a>
                <mdb-mask waves overlay="white-slight"/>
              </a>
            </mdb-view>
            <mdb-card-body class="pb-0">
              <a href="" class="cyan-text"><h5 class="font-weight-bold mt-2 mb-3"><mdb-icon icon="phone" class="pr-2"/>Technology</h5></a>
              <h4 class="font-weight-bold mb-3">Title of the news</h4>
              <p>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit cupidatat proident voluptatem quia numquam.</p>
              <mdb-btn color="success" rounded>View more</mdb-btn>
            </mdb-card-body>
          </mdb-col>
        </mdb-row>
      </section>

      <section class="my-5">
        <h2 class="h1-responsive font-weight-bold text-center my-5">Our best projects</h2>
        <p class="grey-text w-responsive text-center mx-auto mb-5">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit est laborum.</p>
        <mdb-row>
          <mdb-col lg="5" class="mb-lg-0 mb-5">
            <img src="https://mdbootstrap.com/img/Photos/Others/images/83.jpg" alt="Sample project image" class="img-fluid rounded z-depth-1" />
          </mdb-col>
          <mdb-col lg="7">
            <mdb-row class="mb-3">
              <mdb-col md="1" size="2">
                <mdb-icon icon="chart-bar" size="2x" class="indigo-text"/>
              </mdb-col>
              <mdb-col md="11" size="10">
                <h5 class="font-weight-bold mb-3">Marketing</h5>
                <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing. Reprehenderit maiores nam, aperiam minima elit assumenda voluptate velit.</p>
              </mdb-col>
            </mdb-row>
            <mdb-row class="mb-3">
              <mdb-col md="1" size="2">
                <mdb-icon icon="music" size="2x" class="pink-text"/>
              </mdb-col>
              <mdb-col md="11" size="10">
                <h5 class="font-weight-bold mb-3">Entertainment</h5>
                <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing. Reprehenderit maiores nam, aperiam minima elit assumenda voluptate velit.</p>
              </mdb-col>
            </mdb-row>
            <mdb-row class="mb-3">
              <mdb-col md="1" size="2">
                <mdb-icon far icon="smile" size="2x" class="blue-text"/>
              </mdb-col>
              <mdb-col md="11" size="10">
                <h5 class="font-weight-bold mb-3">Communication</h5>
                <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing. Reprehenderit maiores nam, aperiam minima elit assumenda voluptate velit.</p>
              </mdb-col>
            </mdb-row>
          </mdb-col>
        </mdb-row>
        <hr class="my-5"/>
        <mdb-row>
          <mdb-col lg="7">
            <mdb-row class="mb-3">
              <mdb-col md="1" size="2">
                <mdb-icon icon="book" size="2x" class="cyan-text"/>
              </mdb-col>
              <mdb-col md="11" size="10">
                <h5 class="font-weight-bold mb-3">Education</h5>
                <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing. Reprehenderit maiores nam, aperiam minima elit assumenda voluptate velit.</p>
              </mdb-col>
            </mdb-row>
            <mdb-row class="mb-3">
              <mdb-col md="1" size="2">
                <mdb-icon icon="code" size="2x" class="red-text"/>
              </mdb-col>
              <mdb-col md="11" size="10">
                <h5 class="font-weight-bold mb-3">Technology</h5>
                <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing. Reprehenderit maiores nam, aperiam minima elit assumenda voluptate velit.</p>
              </mdb-col>
            </mdb-row>
            <mdb-row class="mb-3">
              <mdb-col md="1" size="2">
                <mdb-icon icon="money-bill-alt" size="2x" class="deep-purple-text"/>
              </mdb-col>
              <mdb-col md="11" size="10">
                <h5 class="font-weight-bold mb-3">Finance</h5>
                <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing. Reprehenderit maiores nam, aperiam minima elit assumenda voluptate velit.</p>
              </mdb-col>
            </mdb-row>
          </mdb-col>
          <mdb-col lg="5">
            <img src="https://mdbootstrap.com/img/Photos/Others/images/82.jpg" alt="Sample project image" class="img-fluid rounded z-depth-1"/>
          </mdb-col>
        </mdb-row>
      </section>

      <section class="text-center my-5">
        <h2 class="h1-responsive font-weight-bold my-5">Our best projects</h2>
        <p class="grey-text w-responsive mx-auto mb-5">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit est laborum.</p>
        <mdb-row>
          <mdb-col md="12" class="mb-4">
            <mdb-card class="card-image" style="background-image: url(https://mdbootstrap.com/img/Photos/Others/img%20%2832%29.jpg)">
              <div class="text-white text-center d-flex align-items-center rgba-black-strong py-5 px-4 rounded">
                <div>
                  <h6 class="purple-text">
                    <mdb-icon icon="plane"></mdb-icon><strong> Travel</strong>
                  </h6>
                  <h3 class="py-3 font-weight-bold">
                    <strong>This is card title</strong>
                  </h3>
                  <p class="pb-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat fugiat, laboriosam, voluptatem, optio vero odio nam sit officia accusamus minus error nisi architecto nulla ipsum dignissimos. Odit sed qui, dolorum!</p>
                  <mdb-btn color="secondary" rounded size="md"><mdb-icon icon="clone" class="left"/> View project</mdb-btn>
                </div>
              </div>
            </mdb-card>
          </mdb-col>
          <mdb-col md="6" class="md-0 mb-4">
            <mdb-card class="card-image" style="backgroundImage: url(https://mdbootstrap.com/img/Photos/Horizontal/Nature/6-col/img%20%2873%29.jpg)">
              <div class="text-white text-center d-flex align-items-center rgba-black-strong py-5 px-4 rounded">
                <div>
                  <h6 class="pink-text">
                    <mdb-icon icon="pie-chart"></mdb-icon><strong> Marketing</strong>
                  </h6>
                  <h3 class="py-3 font-weight-bold">
                    <strong>This is card title</strong>
                  </h3>
                  <p class="pb-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat fugiat, laboriosam, voluptatem, optio vero odio nam sit officia accusamus minus error nisi architecto nulla ipsum dignissimos. Odit sed qui, dolorum!</p>
                  <mdb-btn color="pink" rounded size="md"><mdb-icon icon="clone" class="left"/> View project</mdb-btn>
                </div>
              </div>
            </mdb-card>
          </mdb-col>
          <mdb-col md="6" class="md-0 mb-4">
            <mdb-card class="card-image" style="backgroundImage: url(https://mdbootstrap.com/img/Photos/Horizontal/Nature/6-col/img%20%2873%29.jpg)">
              <div class="text-white text-center d-flex align-items-center rgba-black-strong py-5 px-4 rounded">
                <div>
                  <h6 class="green-text">
                    <mdb-icon icon="eye"></mdb-icon><strong> Entertainment</strong>
                  </h6>
                  <h3 class="py-3 font-weight-bold">
                    <strong>This is card title</strong>
                  </h3>
                  <p class="pb-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat fugiat, laboriosam, voluptatem, optio vero odio nam sit officia accusamus minus error nisi architecto nulla ipsum dignissimos. Odit sed qui, dolorum!</p>
                  <mdb-btn color="success" rounded size="md"><mdb-icon icon="clone" class="left"/> View project</mdb-btn>
                </div>
              </div>
            </mdb-card>
          </mdb-col>
        </mdb-row>
      </section>
    </mdb-container>
  </mdb-container>
</template>

<script>
import { mdbContainer, mdbRow, mdbCol, mdbView, mdbMask, mdbCard, mdbCardBody, mdbBtn, mdbIcon } from 'mdbvue';

export default {
  name: 'ProjectsPage',
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbView,
    mdbMask,
    mdbCard,
    mdbCardBody,
    mdbBtn,
    mdbIcon
  },
};
</script>

<style scoped>
</style>

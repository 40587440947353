import { render, staticRenderFns } from "./ResizePage.vue?vue&type=template&id=78d8a2ac&scoped=true&"
import script from "./ResizePage.vue?vue&type=script&lang=js&"
export * from "./ResizePage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78d8a2ac",
  null
  
)

export default component.exports
import { render, staticRenderFns } from "./MasksPage.vue?vue&type=template&id=3ed9398c&scoped=true&"
import script from "./MasksPage.vue?vue&type=script&lang=js&"
export * from "./MasksPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ed9398c",
  null
  
)

export default component.exports
<template>
  <mdb-container>
    <mdb-row class="mt-5 align-items-center justify-content-start">
      <h4 class="demo-title"><strong>Team Sections</strong></h4>
      <a href="https://mdbootstrap.com/docs/vue/sections/team/" waves-fixed class="border grey-text px-2 border-light rounded ml-2" target="_blank"><mdb-icon icon="graduation-cap" class="mr-2"/>Docs</a>
    </mdb-row>
    <hr />
    <mdb-container>
      <section id="team-section" class="text-center">
        <h2 class="text-center text-md-left pt-4 mt-5">
        <strong>Team v.1 </strong></h2>

        <h2 class="h1-responsive font-weight-bold my-5">Our amazing team</h2>
        <p class="grey-text w-responsive mx-auto mb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam iure provident voluptate esse quasi, veritatis totam voluptas nostrum quisquam eum porro a pariatur veniam.</p>
        <mdb-row>
          <mdb-col lg="3" md="6"  class="mb-lg-0 mb-5">
            <mdb-avatar tag="img" src="https://mdbootstrap.com/img/Photos/Avatars/img%20(20).jpg" circle class="z-depth-1" alt="Sample avatar"/>
            <h5 class="font-weight-bold mt-4 mb-3">Anna Williams</h5>
            <p class="text-uppercase blue-text">Graphic designer</p>
            <p class="grey-text">Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci  sed quia non numquam modi tempora eius.</p>
            <ul class="list-unstyled mb-0">

              <a class="p-2 fa-lg">
                <mdb-icon fab icon="facebook-f" class="blue-text"/>
              </a>
              <a class="p-2 fa-lg">
                <mdb-icon fab icon="twitter" class="blue-text"/>
              </a>
              <a class="p-2 fa-lg">
                <mdb-icon fab icon="instagram" class="blue-text"/>
              </a>
            </ul>
          </mdb-col>

          <mdb-col lg="3" md="6"  class="mb-lg-0 mb-5">
            <mdb-avatar tag="img" src="https://mdbootstrap.com/img/Photos/Avatars/img%20(3).jpg" circle class="z-depth-1" alt="Sample avatar"/>
            <h5 class="font-weight-bold mt-4 mb-3">John Doe</h5>
            <p class="text-uppercase blue-text">Web Developer</p>
            <p class="grey-text">Sed ut perspiciatis unde omnis iste natus error sit voluptatem ipsa accusantium doloremque rem laudantium totam aperiam.</p>
            <ul class="list-unstyled mb-0">

              <a class="p-2 fa-lg">
                <mdb-icon fab icon="facebook-f" class="blue-text"/>
              </a>
              <a class="p-2 fa-lg">
                <mdb-icon fab icon="instagram" class="blue-text"/>
              </a>
            </ul>
          </mdb-col>

          <mdb-col lg="3" md="6"  class="mb-lg-0 mb-5">
            <mdb-avatar tag="img" src="https://mdbootstrap.com/img/Photos/Avatars/img%20(30).jpg" circle class="z-depth-1" alt="Sample avatar"/>
            <h5 class="font-weight-bold mt-4 mb-3">Maria Smith</h5>
            <p class="text-uppercase blue-text">Photographer</p>
            <p class="grey-text">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim est fugiat nulla id eu laborum.</p>
            <ul class="list-unstyled mb-0">

              <a class="p-2 fa-lg">
                <mdb-icon fab icon="facebook-f" class="blue-text"/>
              </a>
              <a class="p-2 fa-lg">
                <mdb-icon fab icon="instagram" class="blue-text"/>
              </a>
              <a class="p-2 fa-lg">
                <mdb-icon fab icon="dribbble" class="blue-text"/>
              </a>
            </ul>
          </mdb-col>

          <mdb-col lg="3" md="6"  class="mb-lg-0 mb-5">
            <mdb-avatar tag="img" src="https://mdbootstrap.com/img/Photos/Avatars/img%20(32).jpg" circle class="z-depth-1" alt="Sample avatar"/>
            <h5 class="font-weight-bold mt-4 mb-3">Tom Adams</h5>
            <p class="text-uppercase blue-text">Backend Developer</p>
            <p class="grey-text">Perspiciatis repellendus ad odit consequuntur, eveniet earum nisi qui consectetur totam officia voluptates perferendis voluptatibus aut.</p>
            <ul class="list-unstyled mb-0">

              <a class="p-2 fa-lg">
                <mdb-icon fab icon="facebook-f" class="blue-text"/>
              </a>
              <a class="p-2 fa-lg">
                <mdb-icon fab icon="github" class="blue-text"/>
              </a>
            </ul>
          </mdb-col>
        </mdb-row>

        <h2 class="text-center text-md-left my-5">
          <strong>Team v.2 </strong>
        </h2>

        <h2 class="h1-responsive font-weight-bold my-5">Our amazing team</h2>
        <p class="grey-text w-responsive mb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam iure provident voluptate esse quasi, veritatis totam voluptas nostrum quisquam eum porro a pariatur veniam.</p>
        <mdb-row>
          <mdb-col md="4" class="mb-md-0 mb-5">
            <mdb-avatar tag="img" src="https://mdbootstrap.com/img/Photos/Avatars/img%20(10).jpg" class="rounded z-depth-1-half img-fluid" alt="Sample avatar"/>
            <h4 class="font-weight-bold dark-grey-text my-4">Maria Kate</h4>
            <h6 class="text-uppercase grey-text mb-3">Photographer</h6>
            <mdb-btn tag="a" floating size="sm" class="mx-1 mb-0 btn-fb">
              <mdb-icon fab icon="facebook-f"/>
            </mdb-btn>
            <mdb-btn tag="a" floating size="sm" class="mx-1 mb-0 btn-dribbble">
              <mdb-icon fab icon="dribbble"/>
            </mdb-btn>
            <mdb-btn tag="a" floating size="sm" class="mx-1 mb-0 btn-tw">
              <mdb-icon fab icon="twitter"/>
            </mdb-btn>
          </mdb-col>

          <mdb-col md="4" class="mb-md-0 mb-5">
            <mdb-avatar tag="img" src="https://mdbootstrap.com/img/Photos/Avatars/img%20(27).jpg" class="rounded z-depth-1-half img-fluid" alt="Sample avatar"/>
            <h4 class="font-weight-bold dark-grey-text my-4">John Doe</h4>
            <h6 class="text-uppercase grey-text mb-3">Front-end Developer</h6>
            <mdb-btn tag="a" floating size="sm" class="mx-1 mb-0 btn-email">
              <mdb-icon icon="envelope"/>
            </mdb-btn>
            <mdb-btn tag="a" floating size="sm" class="mx-1 mb-0 btn-fb">
              <mdb-icon fab icon="facebook-f"/>
            </mdb-btn>
            <mdb-btn tag="a" floating size="sm" class="mx-1 mb-0 btn-git">
              <mdb-icon fab icon="github"/>
            </mdb-btn>
          </mdb-col>

          <mdb-col md="4" class="mb-md-0 mb-5">
            <mdb-avatar tag="img" src="https://mdbootstrap.com/img/Photos/Avatars/img%20(31).jpg" class="rounded z-depth-1-half img-fluid" alt="Sample avatar"/>
            <h4 class="font-weight-bold dark-grey-text my-4">Sarah Melyah</h4>
            <h6 class="text-uppercase grey-text mb-3">Web Developer</h6>
            <mdb-btn tag="a" floating size="sm" class="mx-1 mb-0 btn-li">
              <mdb-icon fab icon="linkedin-in"/>
            </mdb-btn>
            <mdb-btn tag="a" floating size="sm" class="mx-1 mb-0 btn-tw">
              <mdb-icon fab icon="twitter"/>
            </mdb-btn>
            <mdb-btn tag="a" floating size="sm" class="mx-1 mb-0 btn-pin">
              <mdb-icon fab icon="pinterest-p"/>
            </mdb-btn>
          </mdb-col>

            </mdb-row>

        <h2 class="title text-center text-md-left pt-4 mt-5">
          <strong>Team v.3 </strong>
        </h2>

        <h2 class="h1-responsive font-weight-bold my-5">Our amazing team</h2>
        <p class="grey-text w-responsive mx-auto mb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam iure provident voluptate esse quasi, veritatis totam voluptas nostrum quisquam eum porro a pariatur veniam.</p>
        <mdb-row class="text-md-left">
          <mdb-col lg="6" md="12" class="mb-5">
            <mdb-col md="4" lg="6" class="float-left">
              <mdb-avatar src="https://mdbootstrap.com/img/Photos/Avatars/img%20(32).jpg" class="mx-auto mb-md-0 mb-4 z-depth-1 img-fluid" round tag="img" alt="Sample avatar"/>
            </mdb-col>
            <mdb-col md="8" lg="6" class="float-right">
              <h4 class="font-weight-bold mb-3">John Doe</h4>
              <h6 class="font-weight-bold grey-text mb-3">Web Designer</h6>
              <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos id officiis hic tenetur.</p>
              <a class="p-2 fa-lg fb-ic">
                <mdb-icon fab icon="facebook-f"/>
              </a>
              <a class="p-2 fa-lg tw-ic">
                <mdb-icon fab icon="twitter"/>
              </a>
              <a class="p-2 fa-lg dribbble-ic">
                <mdb-icon fab icon="dribbble"/>
              </a>
            </mdb-col>
          </mdb-col>

          <mdb-col lg="6" md="12" class="mb-5">
            <mdb-col md="4" lg="6" class="float-left">
              <mdb-avatar src="https://mdbootstrap.com/img/Photos/Avatars/img%20(20).jpg" class="mx-auto mb-md-0 mb-4 z-depth-1 img-fluid" round tag="img" alt="Sample avatar"/>
            </mdb-col>
            <mdb-col md="8" lg="6" class="float-right">
              <h4 class="font-weight-bold mb-3">Maria Kate</h4>
              <h6 class="font-weight-bold grey-text mb-3">Photographer</h6>
              <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos id officiis hic tenetur.</p>
              <a class="p-2 fa-lg fb-ic">
                <mdb-icon fab icon="facebook-f"/>
              </a>
              <a class="p-2 fa-lg yt-ic">
                <mdb-icon fab icon="youtube"/>
              </a>
              <a class="p-2 fa-lg ins-ic">
                <mdb-icon fab icon="instagram"/>
              </a>
            </mdb-col>
          </mdb-col>

          <mdb-col lg="6" md="12" class="mb-5">
            <mdb-col md="4" lg="6" class="float-left">
              <mdb-avatar src="https://mdbootstrap.com/img/Photos/Avatars/img%20(26).jpg" class="mx-auto mb-md-0 mb-4 z-depth-1 img-fluid" round tag="img" alt="Sample avatar"/>
            </mdb-col>
            <mdb-col md="8" lg="6" class="float-right">
              <h4 class="font-weight-bold mb-3">Anna Deynah</h4>
              <h6 class="font-weight-bold grey-text mb-3">Web Developer</h6>
              <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos id officiis hic tenetur.</p>
              <a class="p-2 fa-lg fb-ic">
                <mdb-icon fab icon="facebook-f"/>
              </a>
              <a class="p-2 fa-lg tw-ic">
                <mdb-icon fab icon="twitter"/>
              </a>
              <a class="p-2 fa-lg github-ic">
                <mdb-icon fab icon="github"/>
              </a>
            </mdb-col>
          </mdb-col>

          <mdb-col lg="6" md="12" class="mb-5">
            <mdb-col md="4" lg="6" class="float-left">
              <mdb-avatar src="https://mdbootstrap.com/img/Photos/Avatars/img%20(29).jpg" class="mx-auto mb-md-0 mb-4 z-depth-1 img-fluid" round tag="img" alt="Sample avatar"/>
            </mdb-col>
            <mdb-col md="8" lg="6" class="float-right">
              <h4 class="font-weight-bold mb-3">Sarah Melyah</h4>
              <h6 class="font-weight-bold grey-text mb-3">Front-end Developer</h6>
              <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod eos id officiis hic tenetur.</p>
              <a class="p-2 fa-lg gplus-ic">
                <mdb-icon fab icon="google-plus-g"/>
              </a>
              <a class="p-2 fa-lg li-ic">
                <mdb-icon fab icon="linkedin-in"/>
              </a>
              <a class="p-2 fa-lg email-ic">
                <mdb-icon icon="envelope"/>
              </a>
            </mdb-col>
          </mdb-col>
        </mdb-row>

      </section>
    </mdb-container>
  </mdb-container>
</template>

<script>
import { mdbContainer, mdbRow, mdbCol, mdbAvatar, mdbIcon, mdbBtn } from 'mdbvue';

export default {
  name: 'TeamPage',
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbAvatar,
    mdbIcon,
    mdbBtn
  }
};
</script>

<style scoped>
.avatar {
  max-width: 200px;
}
.avatar.rounded-circle {
  max-width: 150px;
}

</style>

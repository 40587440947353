<template>
  <mdb-container>
    <mdb-row class="mt-5 align-items-center justify-content-start">
      <h4 class="demo-title"><strong>Footer</strong></h4>
      <a href="https://mdbootstrap.com/docs/vue/navigation/footer/?utm_source=DemoApp&utm_medium=MDBVuePro" waves-fixed class="border grey-text px-2 border-light rounded ml-2" target="_blank"><mdb-icon icon="graduation-cap" class="mr-2"/>Docs</a>
    </mdb-row>
    <hr />
    <div class="mt-5">
        <mdb-footer color="stylish-color-dark" class="page-footer font-small pt-4 mt-4">
            <mdb-container class="text-left">
                <mdb-row>
                <mdb-col md="6">
                    <h5 class="text-uppercase mb-4 mt-3 font-weight-bold">Footer Content</h5>
                    <p>Here you can use rows and columns here to organize your footer content. Lorem ipsum dolor sit
                        amet, consectetur adipisicing elit.</p>
                </mdb-col>
                <hr class="clearfix w-100 d-md-none"/>
                <mdb-col md="2">
                    <h5 class="text-uppercase mb-4 mt-3 font-weight-bold">Links</h5>
                    <ul class="list-unstyled">
                        <li><a href="#!">Link 1</a></li>
                        <li><a href="#!">Link 2</a></li>
                        <li><a href="#!">Link 3</a></li>
                        <li><a href="#!">Link 4</a></li>
                    </ul>
                </mdb-col>
                <hr class="clearfix w-100 d-md-none"/>
                <mdb-col md="2">
                    <h5 class="text-uppercase mb-4 mt-3 font-weight-bold">Links</h5>
                    <ul class="list-unstyled">
                        <li><a href="#!">Link 1</a></li>
                        <li><a href="#!">Link 2</a></li>
                        <li><a href="#!">Link 3</a></li>
                        <li><a href="#!">Link 4</a></li>
                    </ul>
                </mdb-col>
                <hr class="clearfix w-100 d-md-none"/>
                <mdb-col md="2">
                    <h5 class="text-uppercase mb-4 mt-3 font-weight-bold">Links</h5>
                    <ul class="list-unstyled">
                        <li><a href="#!">Link 1</a></li>
                        <li><a href="#!">Link 2</a></li>
                        <li><a href="#!">Link 3</a></li>
                        <li><a href="#!">Link 4</a></li>
                    </ul>
                </mdb-col>
                </mdb-row>
            </mdb-container>
            <hr/>
            <div class="text-center py-3">
                <ul class="list-unstyled list-inline mb-0">
                    <li class="list-inline-item">
                        <h5 class="mb-1">Register for free</h5>
                    </li>
                    <li class="list-inline-item"><a href="#" class="btn btn-danger btn-rounded">Sign up!</a></li>
                </ul>
            </div>
            <hr/>
            <div class="text-center">
                <ul class="list-unstyled list-inline">
                    <li class="list-inline-item"><a class="btn-floating btn-sm btn-fb mx-1"><i class="fab fa-facebook-f"> </i></a></li>
                    <li class="list-inline-item"><a class="btn-floating btn-sm btn-tw mx-1"><i class="fab fa-twitter"> </i></a></li>
                    <li class="list-inline-item"><a class="btn-floating btn-sm btn-gplus mx-1"><i class="fab fa-google-plus-g"> </i></a></li>
                    <li class="list-inline-item"><a class="btn-floating btn-sm btn-li mx-1"><i class="fab fa-linkedin-in"> </i></a></li>
                    <li class="list-inline-item"><a class="btn-floating btn-sm btn-dribbble mx-1"><i class="fab fa-dribbble"> </i></a></li>
                </ul>
            </div>
            <div class="footer-copyright text-center py-3">
                <mdb-container fluid>
                    &copy; 2018 Copyright: <a href="https://www.MDBootstrap.com"> MDBootstrap.com </a>
                </mdb-container>
            </div>
        </mdb-footer>
        <mdb-footer color="unique-color-dark" class="page-footer font-small pt-0">
            <div style="backgroundColor: #6351ce">
                <mdb-container class="text-left">
                    <mdb-row class="py-4 d-flex align-items-center">
                        <mdb-col md="6" lg="5" class="text-center text-md-left mb-4 mb-md-0">
                            <h6 class="mb-0 white-text">Get connected with us on social networks!</h6>
                        </mdb-col>
                        <mdb-col md="6" lg="7" class="text-center text-md-right">
                            <a class="fb-ic ml-0"><i class="fab fa-facebook-f white-text mr-lg-4"> </i></a>
                            <a class="tw-ic"><i class="fab fa-twitter white-text mr-lg-4"> </i></a>
                            <a class="gplus-ic"><i class="fab fa-google-plus-g white-text mr-lg-4"> </i></a>
                            <a class="li-ic"><i class="fab fa-linkedin-in white-text mr-lg-4"> </i></a>
                            <a class="ins-ic"><i class="fab fa-instagram white-text mr-lg-4"> </i></a>
                        </mdb-col>
                    </mdb-row>
                </mdb-container>
            </div>
            <mdb-container class="mt-5 mb-4 text-center text-md-left">
                <mdb-row class="mt-3">
                    <mdb-col md="3" lg="4" xl="3" class="mb-4">
                        <h6 class="text-uppercase font-weight-bold"><strong>Company name</strong></h6>
                        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px"/>
                        <p>Here you can use rows and columns here to organize your footer content. Lorem ipsum dolor sit
                            amet, consectetur adipisicing elit.</p>
                    </mdb-col>
                    <mdb-col md="2" lg="2" xl="2" class="mb-4">
                        <h6 class="text-uppercase font-weight-bold"><strong>Products</strong></h6>
                        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px"/>
                        <p><a href="#!">MDBootstrap</a></p>
                        <p><a href="#!">MDWordPress</a></p>
                        <p><a href="#!">BrandFlow</a></p>
                        <p><a href="#!">Bootstrap Angular</a></p>
                    </mdb-col>
                    <mdb-col md="3" lg="2" xl="2" class="mb-4">
                        <h6 class="text-uppercase font-weight-bold"><strong>Useful links</strong></h6>
                        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px"/>
                        <p><a href="#!">Your Account</a></p>
                        <p><a href="#!">Become an Affiliate</a></p>
                        <p><a href="#!">Shipping Rates</a></p>
                        <p><a href="#!">Help</a></p>
                    </mdb-col>
                    <mdb-col md="4" lg="3" xl="3" class="mb-4">
                        <h6 class="text-uppercase font-weight-bold"><strong>Contact</strong></h6>
                        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px"/>
                        <p><i class="fas fa-home mr-3"></i> New York, NY 10012, US</p>
                        <p><i class="fas fa-envelope mr-3"></i> info@example.com</p>
                        <p><i class="fas fa-phone mr-3"></i> + 01 234 567 88</p>
                        <p><i class="fas fa-print mr-3"></i> + 01 234 567 89</p>
                    </mdb-col>
                </mdb-row>
            </mdb-container>
            <div class="footer-copyright text-center py-3">
                <mdb-container fluid>
                    &copy; 2018 Copyright: <a href="https://www.MDBootstrap.com"> MDBootstrap.com </a>
                </mdb-container>
            </div>
        </mdb-footer>
        <mdb-footer color="blue-grey" class="page-footer font-small lighten-5 pt-0">
            <div style="backgroundColor: '#21d192'">
                <mdb-container class="text-left">
                    <mdb-row class="py-4 d-flex align-items-center">
                        <mdb-col md="6" lg="5" class="text-center text-md-left mb-4 mb-md-0">
                            <h6 class="mb-0 white-text">Get connected with us on social networks!</h6>
                        </mdb-col>
                        <mdb-col md="6" lg="7" class="text-center text-md-right">
                            <a class="fb-ic ml-0"><i class="fab fa-facebook-f white-text mr-lg-4"> </i></a>
                            <a class="tw-ic"><i class="fab fa-twitter white-text mr-lg-4"> </i></a>
                            <a class="gplus-ic"><i class="fab fa-google-plus-g white-text mr-lg-4"> </i></a>
                            <a class="li-ic"><i class="fab fa-linkedin-in white-text mr-lg-4"> </i></a>
                            <a class="ins-ic"><i class="fab fa-instagram white-text mr-lg-4"> </i></a>
                        </mdb-col>
                    </mdb-row>
                </mdb-container>
            </div>
            <mdb-container class="mt-5 mb-4 text-center text-md-left">
                <mdb-row class="mt-3">
                    <mdb-col md="3" lg="4" xl="3" class="mb-4 dark-grey-text">
                        <h6 class="text-uppercase font-weight-bold"><strong>Company name</strong></h6>
                        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px"/>
                        <p>Here you can use rows and columns here to organize your footer content. Lorem ipsum dolor sit
                            amet, consectetur adipisicing elit.</p>
                    </mdb-col>
                    <mdb-col md="2" lg="2" xl="2" class="mb-4 dark-grey-text">
                        <h6 class="text-uppercase font-weight-bold"><strong>Products</strong></h6>
                        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px"/>
                        <p><a href="#!" class="dark-grey-text">MDBootstrap</a></p>
                        <p><a href="#!" class="dark-grey-text">MDWordPress</a></p>
                        <p><a href="#!" class="dark-grey-text">BrandFlow</a></p>
                        <p><a href="#!" class="dark-grey-text">Bootstrap Angular</a></p>
                    </mdb-col>
                    <mdb-col md="3" lg="2" xl="2" class="mb-4 dark-grey-text">
                        <h6 class="text-uppercase font-weight-bold"><strong>Useful links</strong></h6>
                        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px"/>
                        <p><a href="#!" class="dark-grey-text">Your Account</a></p>
                        <p><a href="#!" class="dark-grey-text">Become an Affiliate</a></p>
                        <p><a href="#!" class="dark-grey-text">Shipping Rates</a></p>
                        <p><a href="#!" class="dark-grey-text">Help</a></p>
                    </mdb-col>
                    <mdb-col md="4" lg="3" xl="3" class="mb-4 dark-grey-text">
                        <h6 class="text-uppercase font-weight-bold"><strong>Contact</strong></h6>
                        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px"/>
                        <p><i class="fas fa-home mr-3"></i> New York, NY 10012, US</p>
                        <p><i class="fas fa-envelope mr-3"></i> info@example.com</p>
                        <p><i class="fas fa-phone mr-3"></i> + 01 234 567 88</p>
                        <p><i class="fas fa-print mr-3"></i> + 01 234 567 89</p>
                    </mdb-col>
                </mdb-row>
            </mdb-container>
            <div class="footer-copyright text-center py-3">
                <mdb-container fluid>
                    &copy; 2018 Copyright: <a href="https://www.MDBootstrap.com"> MDBootstrap.com </a>
                </mdb-container>
            </div>
        </mdb-footer>
        <mdb-footer color="indigo" class="font-small pt-0">
            <mdb-container>
                <mdb-row>
                    <mdb-col md="12">
                        <div class="mb-5 flex-center">
                        <a class="fb-ic"><i class="fab fa-facebook-f fa-lg white-text mr-md-5 mr-3 fa-2x"> </i></a>
                        <a class="tw-ic"><i class="fab fa-twitter fa-lg white-text mr-md-5 mr-3 fa-2x"> </i></a>
                        <a class="gplus-ic"><i class="fab fa-google-plus-g fa-lg white-text mr-md-5 mr-3 fa-2x"> </i></a>
                        <a class="li-ic"><i class="fab fa-linkedin-in fa-lg white-text mr-md-5 mr-3 fa-2x"> </i></a>
                        <a class="ins-ic"><i class="fab fa-instagram fa-lg white-text mr-md-5 mr-3 fa-2x"> </i></a>
                        <a class="pin-ic"><i class="fab fa-pinterest fa-lg white-text fa-2x"> </i></a>
                        </div>
                    </mdb-col>
                </mdb-row>
            </mdb-container>
            <div class="footer-copyright text-center py-3">
                <mdb-container fluid>
                    &copy; 2018 Copyright: <a href="https://www.MDBootstrap.com"> MDBootstrap.com </a>
                </mdb-container>
            </div>
        </mdb-footer>
        <mdb-footer color="stylish-color-dark" class="font-small pt-4 mt-4">
            <mdb-container class="text-center text-md-left">
                <mdb-row class="text-center text-md-left mt-3 pb-3">
                    <mdb-col md="3" lg="3" xl="3" class="mx-auto mt-3">
                        <h6 class="text-uppercase mb-4 font-weight-bold">Company name</h6>
                        <p>Here you can use rows and columns here to organize your footer content. Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                    </mdb-col>
                    <hr class="w-100 clearfix d-md-none"/>
                    <mdb-col md="2" lg="2" xl="2" class="mx-auto mt-3">
                        <h6 class="text-uppercase mb-4 font-weight-bold">Products</h6>
                        <p><a href="#!">MDBootstrap</a></p>
                        <p><a href="#!">MDWordPress</a></p>
                        <p><a href="#!">BrandFlow</a></p>
                        <p><a href="#!">Bootstrap Angular</a></p>
                    </mdb-col>
                    <hr class="w-100 clearfix d-md-none"/>
                    <mdb-col md="3" lg="2" xl="2" class="mx-auto mt-3">
                        <h6 class="text-uppercase mb-4 font-weight-bold">Useful links</h6>
                        <p><a href="#!">Your Account</a></p>
                        <p><a href="#!">Become an Affiliate</a></p>
                        <p><a href="#!">Shipping Rates</a></p>
                        <p><a href="#!">Help</a></p>
                    </mdb-col>
                    <hr class="w-100 clearfix d-md-none"/>
                    <mdb-col md="4" lg="3" xl="3" class="mx-auto mt-3">
                        <h6 class="text-uppercase mb-4 font-weight-bold">Contact</h6>
                        <p><i class="fas fa-home mr-3"></i> New York, NY 10012, US</p>
                        <p><i class="fas fa-envelope mr-3"></i> info@gmail.com</p>
                        <p><i class="fas fa-phone mr-3"></i> + 01 234 567 88</p>
                        <p><i class="fas fa-print mr-3"></i> + 01 234 567 89</p>
                    </mdb-col>
                </mdb-row>
                <hr/>
                <mdb-row class="d-flex align-items-center">
                    <mdb-col md="8" lg="8">
                        <p class="text-center text-md-left grey-text">&copy; 2018 Copyright: <a href="https://www.MDBootstrap.com"> MDBootstrap.com </a></p>
                    </mdb-col>
                    <mdb-col md="4" lg="4" class="ml-lg-0">
                        <div class="text-center text-md-right">
                            <ul class="list-unstyled list-inline">
                                <li class="list-inline-item"><a class="btn-floating btn-sm rgba-white-slight mx-1"><i class="fab fa-facebook-f"></i></a></li>
                                <li class="list-inline-item"><a class="btn-floating btn-sm rgba-white-slight mx-1"><i class="fab fa-twitter"></i></a></li>
                                <li class="list-inline-item"><a class="btn-floating btn-sm rgba-white-slight mx-1"><i class="fab fa-google-plus-g"></i></a></li>
                                <li class="list-inline-item"><a class="btn-floating btn-sm rgba-white-slight mx-1"><i class="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                    </mdb-col>
                </mdb-row>
            </mdb-container>
        </mdb-footer>
        <mdb-footer color="indigo" class="font-small pt-0">
            <mdb-container>
                <mdb-row class="pt-5 mb-3 text-center d-flex justify-content-center">
                    <mdb-col md="2" class="b-3">
                        <h6 class="title font-weight-bold"><a href="#!">About us</a></h6>
                    </mdb-col>
                    <mdb-col md="2" class="b-3">
                        <h6 class="title font-weight-bold"><a href="#!">Products</a></h6>
                    </mdb-col>
                    <mdb-col md="2" class="b-3">
                        <h6 class="title font-weight-bold"><a href="#!">Awards</a></h6>
                    </mdb-col>
                    <mdb-col md="2" class="b-3">
                        <h6 class="title font-weight-bold"><a href="#!">Help</a></h6>
                    </mdb-col>
                    <mdb-col md="2" class="b-3">
                        <h6 class="title font-weight-bold"><a href="#!">Contact</a></h6>
                    </mdb-col>
                </mdb-row>
                <hr class="rgba-white-light" style="margin: '0 15%'"/>
                <mdb-row class="d-flex text-center justify-content-center mb-md-0 mb-4">
                    <mdb-col md="8" sm="12" class="mt-5">
                        <p style="lineHeight: '1.7rem'">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                            totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae
                            vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit
                    aut fugit, sed quia consequuntur.</p>
                    </mdb-col>
                </mdb-row>
                <hr class="clearfix d-md-none rgba-white-light" style="margin: '10% 15% 5%'"/>
                <mdb-row class="pb-3">
                    <mdb-col md="12">
                        <div class="mb-5 flex-center">
                            <a class="fb-ic"><i class="fab fa-facebook-f fa-lg white-text mr-md-4"> </i></a>
                            <a class="tw-ic"><i class="fab fa-twitter fa-lg white-text mr-md-4"> </i></a>
                            <a class="gplus-ic"><i class="fab fa-google-plus-g fa-lg white-text mr-md-4"> </i></a>
                            <a class="li-ic"><i class="fab fa-linkedin-in fa-lg white-text mr-md-4"> </i></a>
                            <a class="ins-ic"><i class="fab fa-instagram fa-lg white-text mr-md-4"> </i></a>
                            <a class="pin-ic"><i class="fab fa-pinterest fa-lg white-text"> </i></a>
                        </div>
                    </mdb-col>
                </mdb-row>
            </mdb-container>
            <div class="footer-copyright text-center py-3">
                <mdb-container fluid>
                    &copy; 2018 Copyright: <a href="https://www.MDBootstrap.com"> MDBootstrap.com </a>
                </mdb-container>
            </div>
        </mdb-footer>
        <mdb-footer color="mdb-color" class="font-small lighten-3 pt-4 mt-4">
            <mdb-container class="text-center text-md-left">
              <mdb-row class="my-4">
                <mdb-col md="4" lg="4">
                  <h5 class="text-uppercase mb-4 font-weight-bold">Footer Content</h5>
                  <p>Here you can use rows and columns here to organize your footer content.</p>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam iure provident
                      voluptate esse quasi, veritatis totam voluptas nostrum. </p>
                </mdb-col>
                <hr class="clearfix w-100 d-md-none"/>
                <mdb-col md="2" lg="2" class="ml-auto">
                  <h5 class="text-uppercase mb-4 font-weight-bold">About</h5>
                  <ul class="list-unstyled">
                    <p><a href="#!">PROJECTS</a></p>
                    <p><a href="#!">ABOUT US</a></p>
                    <p><a href="#!">BLOG</a></p>
                    <p><a href="#!">AWARDS</a></p>
                  </ul>
                </mdb-col>
                <hr class="clearfix w-100 d-md-none"/>
                <mdb-col md="5" lg="3">
                  <h5 class="text-uppercase mb-4 font-weight-bold">Address</h5>
                  <p><i class="fas fa-home mr-3"></i> New York, NY 10012, US</p>
                  <p><i class="fas fa-envelope mr-3"></i> info@example.com</p>
                  <p><i class="fas fa-phone mr-3"></i> + 01 234 567 88</p>
                  <p><i class="fas fa-print mr-3"></i> + 01 234 567 89</p>
                </mdb-col>
                <hr class="clearfix w-100 d-md-none"/>
                <mdb-col md="2" lg="2" class="text-center">
                  <h5 class="text-uppercase mb-4 font-weight-bold">About</h5>
                  <div class="mt-2 ">
                    <a type="button" class="btn-floating btn-small btn-fb"><i class="fab fa-facebook-f"></i></a>
                    <a type="button" class="btn-floating btn-small btn-tw"><i class="fab fa-twitter"></i></a>
                    <a type="button" class="btn-floating btn-small btn-gplus"><i class="fab fa-google-plus-g"></i></a>
                    <a type="button" class="btn-floating btn-small btn-dribbble"><i class="fab fa-dribbble"></i></a>
                  </div>
                </mdb-col>
                <hr class="clearfix w-100 d-md-none"/>
              </mdb-row>
            </mdb-container>
            <div class="footer-copyright text-center py-3">
              <mdb-container fluid>
                  &copy; 2018 Copyright: <a href="https://www.MDBootstrap.com"> MDBootstrap.com </a>
              </mdb-container>
            </div>
        </mdb-footer>
    </div>
  </mdb-container>
</template>

<script>
import { mdbFooter, mdbContainer, mdbRow, mdbCol, mdbIcon } from 'mdbvue';

export default {
  name: 'FooterProPage',
  components: {
    mdbFooter,
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbIcon
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<template>
  <mdb-container class="mt-5">
    <mdb-row class="mt-5">
      <h4 class="demo-title"><strong>Gradient </strong></h4>
      <a href="https://mdbootstrap.com/docs/vue/css/gradients/?utm_source=DemoApp&utm_medium=MDBVueFree" waves-fixed class="border grey-text px-2 border-light rounded ml-2" target="_blank"><mdb-icon icon="graduation-cap" class="mr-2"/>Docs</a>
    </mdb-row>
    <hr class="mb-5" />
    <h4 class="my-4">Basic gradients</h4>
    <mdb-row class="mt-4 text-center">

      <mdb-col md="3" class="mb-4">
        <div class="purple-gradient color-block-5 mb-3 mx-auto rounded-circle z-depth-1"></div>
        <h5 class="mb-0">purple-gradient</h5>
      </mdb-col>

      <mdb-col md="3" class="mb-4">
        <div class="blue-gradient color-block-5 mb-3 mx-auto rounded-circle z-depth-1"></div>
        <h5 class="mb-0">blue-gradient</h5>
      </mdb-col>

      <mdb-col md="3" class="mb-4">
        <div class="aqua-gradient color-block-5 mb-3 mx-auto rounded-circle z-depth-1"></div>
        <h5 class="mb-0">aqua-gradient</h5>
      </mdb-col>

      <mdb-col md="3" class="mb-4">
        <div class="peach-gradient color-block-5 mb-3 mx-auto rounded-circle z-depth-1"></div>
        <h5 class="mb-0">peach-gradient</h5>
      </mdb-col>

    </mdb-row>

    <h4 class="my-4">Additional gradients</h4>
    <section class="section-preview text-center">

      <mdb-row class="mt-4">

        <mdb-col md="4" class="mb-4">
          <div class="warm-flame-gradient color-block-5 mb-3 mx-auto rounded-circle z-depth-1-half"></div>
          <h5>warm-flame-gradient</h5>
        </mdb-col>

        <mdb-col md="4" class="mb-4">
          <div class="night-fade-gradient color-block-5 mb-3 mx-auto rounded-circle z-depth-1-half"></div>
          <h5>night-fade-gradient</h5>
        </mdb-col>

        <mdb-col md="4" class="mb-4">
          <div class="spring-warmth-gradient color-block-5 mb-3 mx-auto rounded-circle z-depth-1-half"></div>
          <h5>spring-warmth-gradient</h5>
        </mdb-col>

      </mdb-row>

      <mdb-row>

        <mdb-col md="4" class="mb-4">
          <div class="juicy-peach-gradient color-block-5 mb-3 mx-auto rounded-circle z-depth-1-half"></div>
          <h5>juicy-peach-gradient</h5>
        </mdb-col>

        <mdb-col md="4" class="mb-4">
          <div class="young-passion-gradient color-block-5 mb-3 mx-auto rounded-circle z-depth-1-half"></div>
          <h5>young-passion-gradient</h5>
        </mdb-col>

        <mdb-col md="4">
          <div class="rainy-ashville-gradient color-block-5 mb-3 mx-auto rounded-circle z-depth-1-half"></div>
          <h5>rainy-ashville-gradient</h5>
        </mdb-col>

      </mdb-row>

      <mdb-row>

        <mdb-col md="4" class="mb-4">
          <div class="sunny-morning-gradient color-block-5 mb-3 mx-auto rounded-circle z-depth-1-half"></div>
          <h5>sunny-morning-gradient</h5>
        </mdb-col>

        <mdb-col md="4" class="mb-4">
          <div class="lady-lips-gradient color-block-5 mb-3 mx-auto rounded-circle z-depth-1-half"></div>
          <h5>lady-lips-gradient</h5>
        </mdb-col>

        <mdb-col md="4" class="mb-4">
          <div class="winter-neva-gradient color-block-5 mb-3 mx-auto rounded-circle z-depth-1-half"></div>
          <h5>winter-neva-gradient</h5>
        </mdb-col>

      </mdb-row>

      <mdb-row>

        <mdb-col md="4" class="mb-4">
          <div class="frozen-dreams-gradient color-block-5 mb-3 mx-auto rounded-circle z-depth-1-half"></div>
          <h5>frozen-dreams-gradient</h5>
        </mdb-col>

        <mdb-col md="4" class="mb-4">
          <div class="dusty-grass-gradient color-block-5 mb-3 mx-auto rounded-circle z-depth-1-half"></div>
          <h5>dusty-grass-gradient</h5>
        </mdb-col>

        <mdb-col md="4" class="mb-4">
          <div class="tempting-azure-gradient color-block-5 mb-3 mx-auto rounded-circle z-depth-1-half"></div>
          <h5>tempting-azure-gradient</h5>
        </mdb-col>

      </mdb-row>

      <mdb-row>

        <mdb-col md="4" class="mb-4">
          <div class="heavy-rain-gradient color-block-5 mb-3 mx-auto rounded-circle z-depth-1-half"></div>
          <h5>heavy-rain-gradient</h5>
        </mdb-col>

        <mdb-col md="4" class="mb-4">
          <div class="amy-crisp-gradient color-block-5 mb-3 mx-auto rounded-circle z-depth-1-half"></div>
          <h5>amy-crisp-gradient</h5>
        </mdb-col>

        <mdb-col md="4" class="mb-4">
          <div class="mean-fruit-gradient color-block-5 mb-3 mx-auto rounded-circle z-depth-1-half"></div>
          <h5>mean-fruit-gradient</h5>
        </mdb-col>

      </mdb-row>

      <mdb-row>

        <mdb-col md="4" class="mb-4">
          <div class="deep-blue-gradient color-block-5 mb-3 mx-auto rounded-circle z-depth-1-half"></div>
          <h5>deep-blue-gradient</h5>
        </mdb-col>

        <mdb-col md="4" class="mb-4">
          <div class="ripe-malinka-gradient color-block-5 mb-3 mx-auto rounded-circle z-depth-1-half"></div>
          <h5>ripe-malinka-gradient</h5>
        </mdb-col>

        <mdb-col class="col-md-4">
          <div class="cloudy-knoxville-gradient color-block-5 mb-3 mx-auto rounded-circle z-depth-1-half"></div>
          <h5>cloudy-knoxville-gradient</h5>
        </mdb-col>

      </mdb-row>

      <mdb-row>

        <mdb-col md="4" class="mb-4">
          <div class="morpheus-den-gradient color-block-5 mb-3 mx-auto rounded-circle z-depth-1-half"></div>
          <h5>morpheus-den-gradient</h5>
        </mdb-col>

        <mdb-col md="4" class="mb-4">
          <div class="rare-wind-gradient color-block-5 mb-3 mx-auto rounded-circle z-depth-1-half"></div>
          <h5>rare-wind-gradient</h5>
        </mdb-col>

        <mdb-col md="4" class="mb-4">
          <div class="near-moon-gradient color-block-5 mb-3 mx-auto rounded-circle z-depth-1-half"></div>
          <h5>near-moon-gradient</h5>
        </mdb-col>

      </mdb-row>

    </section>

    <h4 class="my-4">Gradient on the image</h4>
    <mdb-row>

      <mdb-col md="6" class="mb-4">
          <h3 class="secondary-heading mb-4">
            Before
          </h3>
          <div>
              <img src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/6-col/img (122).jpg" class="img-fluid" alt="My photo">
          </div>
      </mdb-col>

      <mdb-col md="6">
          <h3 class="secondary-heading mb-4">
            After
          </h3>
          <div class="img-gradient">
              <img src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/6-col/img (122).jpg" class="img-fluid" alt="My photo">
          </div>
      </mdb-col>

    </mdb-row>

  </mdb-container>
</template>

<script>
import { mdbContainer, mdbRow, mdbCol, mdbIcon } from "mdbvue";

export default {
  name: "GradientPage",
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbIcon
  }
};
</script>

<style scoped>
h4 {
  margin: 0;
}
.color-block-5 {
  height: 200px;
  width: 200px;
}
.img-gradient{
  position:relative;
  display:inline-block;
}
.img-gradient:after {
  content:'';
  position:absolute;
  left:0; top:0;
  width:100%; height:100%;
  display:inline-block;
  background: -moz-linear-gradient(top, rgba(0,47,75,0.5) 0%, rgba(220, 66, 37, 0.5) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(220, 66, 37, 0.5)), color-stop(100%,rgba(0,47,75,0.5))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}
.img-gradient img{
  display:block;
}
</style>
